<template>
  <div class="page" style="margin-top: -80px">
    <center>
      <div class="new-item-content">
        <div id="printDiv" v-show="printShow">
          <COAOilEhxto
            :printModel="this.$data"
            style="height-max: 1000px; width: 100vw"
          ></COAOilEhxto>
        </div>
        <!-- Base Info -->
        <div class="base-info">
          <div></div>
          <div>
            <div>
              <span>编辑打印标题：</span>

              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoEditPrintTitleCheckbox"
                  placeholder="请输入"
                ></el-checkbox>
                <el-input
                  v-show="form.BaseInfoEditPrintTitleCheckbox"
                  v-model="form.BaseInfoEditPrintTitle"
                  placeholder="请输入"
                  style="margin-left: 10px; width: 575px"
                ></el-input>
              </div>
            </div>
            <div>
              <span>原产地：</span>
              <el-input
                v-model="form.BaseInfoCountryofOrigin"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>生产日期2：</span>
              <el-date-picker
                type="date"
                v-model="form.BaseInfoManufacturingDate"
                placeholder="Please Select Date"
                @change="manufacturingDateChange"
              ></el-date-picker>
            </div>
            <div>
              <span>有效期：</span>
              <el-date-picker
                type="date"
                v-model="form.BaseInfoExpirationDate"
                placeholder="Please Select Date"
              ></el-date-picker>
            </div>
            <!-- <div>
              <span style="margin-left: 46px">
                <el-input
                  v-model="form.BaseInfoCustomRowKey1"
                  class="custom-row"
                  placeholder="请输入名称"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.BaseInfoCustomRowValue1"
                  class="custom-row"
                  placeholder="请输入值"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addBaseCustomRow(1, 0)"
                >+</el-button>
            </div>
            
            <div
              v-for="(item, baseIndex) in baseCustomFieldAttr"
              :key="baseIndex + 1000"
            >
              <span style="margin-left: 96px">
                <el-input
                  v-model="item.customKey"
                  placeholder="请输入名称"
                  class="custom-row"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="item.customValue"
                  class="custom-row"
                  placeholder="请输入值"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addBaseCustomRow(1, baseIndex)"
                >+</el-button
              >
              <el-button
                type="danger"
                class="button-new-tag"
                size="small"
                @click="addBaseCustomRow(0, baseIndex)"
                >-</el-button
              >
            </div> -->
          </div>
        </div>
        <!-- General Specification -->
        <div class="base-info">
          <div>标准规格</div>
          <div>
            <div style="height: auto">
              <span>通用名/其他名称：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in commonNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(commonNameArr, tag, i)"
                    @close="handleClose(tag, 'commonNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="commonNameInputVisible"
                    v-model="commonNameInputValue"
                    ref="commonNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="commonNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'commonNameInputVisible',
                        'commonNameSaveTagInput'
                      )
                    "
                    >+ 添加通用名/其他名称</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>INCI名称：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in inclNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(inclNameArr, tag, i)"
                    @close="handleClose(tag, 'inclNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="inclNameInputVisible"
                    v-model="inclNameInputValue"
                    ref="inclNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="inciNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                    "
                    >+ 添加INCI名称</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>植物学名：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in botanicalNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(botanicalNameArr, tag, i)"
                    @close="handleClose(tag, 'botanicalNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="botanicalNameInputVisible"
                    v-model="botanicalNameInputValue"
                    ref="botanicalNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="botanicalNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'botanicalNameInputVisible',
                        'botanicalNameSaveTagInput'
                      )
                    "
                    >+ 添加植物学名</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>使用部位：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in partUsedArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(partUsedArr, tag, i)"
                    @close="handleClose(tag, 'partUsedArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="partUsedInputVisible"
                    v-model="partUsedInputValue"
                    ref="partUsedSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="partUsedD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('partUsedInputVisible', 'partUsedSaveTagInput')
                    "
                    >+ 添加使用部位</el-button
                  >
                </div>
              </div>
            </div>
            
          <div style="height: auto">
            <div style="display: inline-block; text-align: left">
            <table style="border: 1px solid #eee;">
              <tr>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput1"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput2"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput3"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput4"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput5"></el-input>
                </td>
                <td>
                  <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                </td>
              </tr>
              <tr v-for="(item, tableInputIndex) in tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput1"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput2"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput3"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput4"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput5"></el-input>
                </td>
                <td>
                  <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                  <el-button type="danger" class="button-new-tag" size="small" @click="addTableInput(0, tableInputIndex)">-</el-button>
                </td>
              </tr>
            </table>
            </div>
          </div>
          
            <div>
              <span>外观：</span>
              <el-input
                v-model="form.GeneralSpecAppearance"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>溶解度：</span>
              <el-input
                v-model="form.GeneralSpecSolubility"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>植物主要成分：</span>
              <el-input
                v-model="form.GeneralSpecImportantConstituentsInPlant"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>均匀度：</span>
              <el-input
                v-model="form.GeneralSpecHomogeneity"
                class="custom-row"
                placeholder="请输入"
              ></el-input>
            </div>

            <div>
              <span>提取过程：</span>
              <el-input
                v-model="form.GeneralSpecExtractionProcess"
                class="custom-row"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>提取溶剂：</span>
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecSolventForExtraction"
                  class="custom-row"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
            <div>
              <span>农药残留：</span>
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecPesticides"
                  class="custom-row"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
            <div>
              <span>证书：</span>
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCertification"
                  class="custom-row"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
            <div>
              <span>其他成分：</span>
              <el-input
                v-model="form.otherIngredients"
                class="custom-row"
                placeholder="请输入其他成分"
              ></el-input>
            </div>

            <div>
              <span style="margin-left: 48px">
                <el-input
                  v-model="form.GeneralSpecCustomRowKey1"
                  class="custom-row"
                  placeholder="Please Name Input"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue1"
                  class="custom-row"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, 0)"
                >+</el-button>
            </div>

            <div
              v-for="(item, genSpecIndex) in genSpecCustomFieldAttr"
              :key="genSpecIndex + 1"
            >
              <span style="margin-left: 96px">
                <el-input
                  v-model="item.customKey"
                  placeholder="Please Name Input"
                  class="custom-row"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="item.customValue"
                  class="custom-row"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, genSpecIndex)"
                >+</el-button
              >
              <el-button
                type="danger"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(0, genSpecIndex)"
                >-</el-button
              >
            </div>
          </div>
        </div>
        <!-- Analysis -->
        <div class="base-info">
          <div>分析</div>
          <div>
            <table
              class="AnalysisTable"
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td style="width: 25%" class="FontWeight">检测项目</td>
                <td style="width: 25%" class="FontWeight">标准</td>
                <td style="width: 25%" class="FontWeight">结果</td>
                <td style="width: 25%" class="FontWeight">测试方法</td>
              </tr>

              <tr>
                <td class="FontWeight">水分</td>
                <td>
                  <el-input
                    v-model="form.SpecificationMoisture"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultMoisture"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodMoisture"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">比重</td>
                <td>
                  <el-input
                    v-model="form.SpecificationSpecificGravity"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultSpecificGravity"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodSpecificGravity"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">折光率</td>
                <td>
                  <el-input
                    v-model="form.SpecificationRefractiveIndex"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultRefractiveIndex"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodRefractiveIndex"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">旋光度</td>
                <td>
                  <el-input
                    v-model="form.SpecificationOpticalRotation"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultOpticalRotation"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodOpticalRotation"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">总重金属</td>
                <td>
                  <el-input
                    onkeyup="value=value.replace(/[^\d.]|/g,'')"
                    v-model="form.SpecificationTotalHeavyMetals"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalHeavyMetals"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalHeavyMetals"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">砷</td>
                <td>
                  <el-input
                    v-model="form.SpecificationArsenicAS"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultArsenicAS"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodArsenicAS"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">镉</td>
                <td>
                  <el-input
                    v-model="form.SpecificationCadmiumCd"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template></el-input
                  >
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCadmiumCd"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCadmiumCd"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">铅</td>
                <td>
                  <el-input
                    v-model="form.SpecificationLeadPd"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template></el-input
                  >
                </td>
                <td>
                  <el-input
                    v-model="form.ResultLeadPd"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodLeadPd"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">汞</td>
                <td>
                  <el-input
                    v-model="form.SpecificationMercuryHg"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">ppm</div>
                    </template></el-input
                  >
                </td>
                <td>
                  <el-input
                    v-model="form.ResultMercuryHg"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodMercuryHg"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">颜色</td>
                <td>
                  <el-input
                    v-model="form.SpecificationColor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultColor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodColor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">气味</td>
                <td>
                  <el-input
                    v-model="form.SpecificationOdor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultOdor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodOdor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">味道</td>
                <td>
                  <el-input
                    v-model="form.SpecificationTaste"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTaste"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTaste"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey4"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue4"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue4"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue4"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, 0)"
                  >+</el-button>
              </tr>

              <tr
                v-for="(item, analysisIndexEhxto) in analysisCustomFieldAttr"
                :key="analysisIndexEhxto"
              >
                <td class="FontWeight">
                  <el-input
                    v-model="item.customKey"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <el-button
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, analysisIndexEhxto)"
                  >+</el-button
                >
                <el-button
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(0, analysisIndexEhxto)"
                  >-</el-button
                >
              </tr>
            </table>
          </div>
        </div>
        <div class="base-info">
          <div>微生物检验</div>
          <div>
            <table
              class="AnalysisTable"
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td style="width: 25%" class="FontWeight">检测项目</td>
                <td style="width: 25%" class="FontWeight">标准</td>
                <td style="width: 25%" class="FontWeight">结果</td>
                <td style="width: 25%" class="FontWeight">测试方法</td>
              </tr>
              <tr>
                <td class="FontWeight">细菌总数</td>
                <td>
                  <el-input
                    onkeyup="value=value.replace(/[^\d.]|/g,'')"
                    v-model="form.SpecificationTotalPlateCount"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalPlateCount"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalPlateCount"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">酵母和霉菌</td>
                <td>
                  <el-input
                    onkeyup="value=value.replace(/[^\d.]|/g,'')"
                    v-model="form.SpecificationYeastAndMold"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultYeastAndMold"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodYeastAndMold"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">大肠杆菌</td>
                <td>
                  <el-input
                    v-model="form.SpecificationEColi"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultEColi"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodEColi"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">沙门氏菌</td>
                <td>
                  <el-input
                    v-model="form.SpecificationSalmonella"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultSalmonella"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodSalmonella"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">金色葡萄球菌</td>
                <td>
                  <el-input
                    v-model="form.SpecificationStaphAureus"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultStaphAureus"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodStaphAureus"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">大肠菌群</td>
                <td>
                  <el-input
                  @blur="checkSpecificationTotalColiforms()"
                    v-model="form.SpecificationTotalColiforms"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">MPN/g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalColiforms"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalColiforms"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.MTSpecificationCustomRowKey1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTSpecificationCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTResultCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTTestMethodCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, 0)"
                  >+</el-button
                >
              </tr>

              <tr
                v-for="(item, micTestsIndex) in micTestsCustomFieldAttr"
                :key="micTestsIndex"
              >
                <td class="FontWeight">
                  <el-input
                    v-model="item.customKey"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <el-button
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, micTestsIndex)"
                  >+</el-button
                >
                <el-button
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(0, micTestsIndex)"
                  >-</el-button
                >
              </tr>
            </table>
          </div>
        </div>

        <NoteTableData :baseInfo="this.$data"></NoteTableData>
        <div class="other-notes">
          <div>Notes</div>
          <div style="margin-top:0px;">
            <el-input v-model="newNote" placeholder="Please Input" style="width:600px;margin-bottom:10px;margin-right:10px;"></el-input>
            <el-button type="text" @click="addNewNoteTableClick">
              Complete
            </el-button>
          </div>
        </div>
        <div class="base-info">
          <div>注释:</div>
          <div style="width: 80%; text-align: left; font-weight: bold">
            <div>
              * 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。
            </div>
            <div>
              * 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。
            </div>
            <div>
              * 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。
            </div>
            <div v-for="(item, index) in newNoteArr" :key="index" style="margin-top:0px;">
              * {{item.content}}
              <el-button type="text" @click="deleteNewNoteTableClick(index)">
                <i style="color:red;font-size:20px;" class="el-icon-delete"></i>
              </el-button>
            </div>
          </div>
        </div>
        <!-- Save -->
        <div class="save-bottom">
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('Continue Edit')">新增至 Working List</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('Continue Edit')">保存 CoA</el-button>
          <el-button v-if="userInfo.operateStatus" type="success" @click="vertify" :icon="isVerifing ? 'el-icon-loading' : ''">完成</el-button>
          <el-button v-if="userInfo.operateStatus" type="danger" @click="vertifyComplete" :icon="isVerifing ? 'el-icon-loading' : ''">审核</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="updateBySpec()">更新COA</el-button>
          <el-button @click="back">返回</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printPage()">打印</el-button>
          <!-- <el-button type="primary" @click="handleNew('ContinueEdit')">保存{{typeName}}</el-button>
          <el-button type="primary" @click="handleNew('Save&New')">保存并新增{{typeName}}</el-button>
          <el-button type="primary" @click="updateBySpec()">更新COA</el-button>
          <el-button type="warning" 
          :icon="isVerifing ? 'el-icon-loading' : ''" 
          v-if="showVerified && pemission(userInfo.username)" 
          @click="vertify" >Vertify</el-button>
          <el-button @click="back">返回 {{typeName}}</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printPage()">打印</el-button> -->
        </div>
      </div>
    </center>
  </div>
</template>
<script>
var dayjs = require('dayjs');
import base from "@/common/base.js";
import printJS from "print-js";
import COAOilEhxto from "../PrintProductTypeModule/Cn-COA-OilEhxto";
import NoteTableData from "./NoteTableData";
export default {
  props: { baseInfoItemCodeDetail: {} },
  mixins: [base],
  components: { COAOilEhxto,NoteTableData },
  data() {
    return {
      isVerifing: false,
      showVerified:false,
      inclNameArr: [],
      inclNameInputVisible: "",
      inclNameInputValue: "",
      inciNameD: false,

      commonNameArr: [],
      commonNameInputVisible: "",
      commonNameInputValue: "",
      commonNameD: false,

      botanicalNameArr: [],
      botanicalNameInputVisible: "",
      botanicalNameInputValue: "",
      botanicalNameD: false,

      partUsedArr: [],
      partUsedInputVisible: "",
      partUsedInputValue: "",
      partUsedD: false,
      BaseInfoManufacturingDate: "",
      BaseInfoExpirationDate: "",
      printShow: false,
      baseInfoCustomFieldList:[],
      form: {
        baseInfoCustomField:"",
        revNumber:1,
        revDate:[],
        noteTableData:"",
        versionNumber:"",
        BaseInfoEditPrintTitle: "Draco Natural Products, Inc.",
        BaseInfoEditPrintTitleCheckbox: false,
        productType: "",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        BaseInfoLot: "",
        OrderNumber:"",
        BaseInfoCountryofOrigin: "中国",
        BaseInfoManufacturingDate: "",
        BaseInfoExpirationDate: "",
        BaseInfoCustomRowKey1: "",
        BaseInfoCustomRowValue1: "",
        

        GeneralSpecCommonName: "",
        //GeneralSpecProductGrade: "",
        GeneralSpecINCIName: "",
        GeneralSpecBotanicalName: "",
        //GeneralSpecCommonNameOrOtherName: "",
        GeneralSpecPartUsed: "",
        GeneralSpecAppearance: "",
        GeneralSpecSolubility: "",
        //GeneralSpecPercentage: "",
        //GeneralSpecExtractionRatio: "",
        GeneralSpecImportantConstituentsInPlant: "",
        GeneralSpecHomogeneity: "",
        GeneralSpecExtractionProcess: "",
        GeneralSpecProcessingAid: "",
        GeneralSpecSolventForExtraction: "",
        GeneralSpecCarrier: "",
        GeneralSpecPesticides: "",
        GeneralSpecCertification: "",
        otherIngredients: "",

        GeneralSpecCustomRowKey1: "",
        GeneralSpecCustomRowValue1: "",
        GeneralSpecCustomRowKey2: "",
        GeneralSpecCustomRowValue2: "",

        SpecificationMoisture: "",
        SpecificationSpecificGravity: "",
        SpecificationRefractiveIndex: "",
        SpecificationOpticalRotation: "",
        SpecificationTotalHeavyMetals: "",
        SpecificationArsenicAS: "",
        SpecificationCadmiumCd: "",
        SpecificationLeadPd: "",
        SpecificationMercuryHg: "",
        SpecificationColor: "",
        SpecificationOdor: "符合标准",
        SpecificationTaste: "符合标准",

        ResultMoisture: "",
        ResultSpecificGravity: "",
        ResultRefractiveIndex: "",
        ResultOpticalRotation: "",
        ResultTotalHeavyMetals: "<10",
        ResultArsenicAS: "",
        ResultCadmiumCd: "",
        ResultLeadPd: "",
        ResultMercuryHg: "",
        ResultColor: "",
        ResultOdor: "符合标准",
        ResultTaste: "符合标准",

        TestMethodMoisture: "",
        TestMethodSpecificGravity: "",
        TestMethodRefractiveIndex: "",
        TestMethodOpticalRotation: "",
        TestMethodTotalHeavyMetals: "",
        TestMethodArsenicAS: "",
        TestMethodCadmiumCd: "",
        TestMethodLeadPd: "",
        TestMethodMercuryHg: "",
        TestMethodColor: "视觉",
        TestMethodOdor: "感官评定",
        TestMethodTaste: "感官评定",

        SpecificationTotalPlateCount: "",
        SpecificationYeastAndMold: "",
        SpecificationEColi: "",
        SpecificationSalmonella: "",
        SpecificationStaphAureus: "",
        SpecificationTotalColiforms: "",

        ResultTotalPlateCount: "",
        ResultYeastAndMold: "100",
        ResultEColi: "阴性",
        ResultSalmonella: "阴性",
        ResultStaphAureus: "阴性",
        ResultTotalColiforms: "<10 MPN/g",

        TestMethodTotalPlateCount: "",
        TestMethodYeastAndMold: "",
        TestMethodEColi: "",
        TestMethodSalmonella: "",
        TestMethodStaphAureus: "",
        TestMethodTotalColiforms: "",

        SpecificationCustomRowKey1: "",
        SpecificationCustomRowValue1: "",
        ResultCustomRowKey1: "",
        ResultCustomRowValue1: "",
        TestMethodCustomRowKey1: "",
        TestMethodCustomRowValue1: "",

        SpecificationCustomRowKey2: "",
        SpecificationCustomRowValue2: "",
        ResultCustomRowKey2: "",
        ResultCustomRowValue2: "",
        TestMethodCustomRowKey2: "",
        TestMethodCustomRowValue2: "",

        SpecificationCustomRowKey3: "",
        SpecificationCustomRowValue3: "",
        ResultCustomRowKey3: "",
        ResultCustomRowValue3: "",
        TestMethodCustomRowKey3: "",
        TestMethodCustomRowValue3: "",

        SpecificationCustomRowKey4: "",
        SpecificationCustomRowValue4: "",
        ResultCustomRowKey4: "",
        ResultCustomRowValue4: "",
        TestMethodCustomRowKey4: "",
        TestMethodCustomRowValue4: "",

        MTSpecificationCustomRowKey1: "",
        MTSpecificationCustomRowValue1: "",

        // MTResultCustomRowKey1: "",
        // MTResultCustomRowValue1: "",
        // MTTestMethodCustomRowKey1: "",
        // MTTestMethodCustomRowValue1: "",

        // MTSpecificationCustomRowKey2: "",
        // MTSpecificationCustomRowValue2: "",
        // MTResultCustomRowKey2: "",
        // MTResultCustomRowValue2: "",
        // MTTestMethodCustomRowKey2: "",
        // MTTestMethodCustomRowValue2: "",

        // MTSpecificationCustomRowKey3: "",
        // MTSpecificationCustomRowValue3: "",
        // MTResultCustomRowKey3: "",
        // MTResultCustomRowValue3: "",
        // MTTestMethodCustomRowKey3: "",
        // MTTestMethodCustomRowValue3: "",

        // MTSpecificationCustomRowKey4: "",
        // MTSpecificationCustomRowValue4: "",
        // MTResultCustomRowKey4: "",
        // MTResultCustomRowValue4: "",
        // MTTestMethodCustomRowKey4: "",
        // MTTestMethodCustomRowValue4: "",

        baseCustomField: "",
        genSpecCustomField: "",
        analysisCustomField: "",
        micTestsCustomField: "",

        tableInput1:"",
        tableInput2:"",
        tableInput3:"",
        tableInput4:"",
        tableInput5:"",
        tableInputCustomField:"",
      },
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        lot: "",
        updateName:"",
        createTime:base.methods.formatTime(base.methods.getLocalTime(-7),"yyyy-MM-dd hh:mm:ss"),
        jsonValue: {},
      },
      createTime: this.formatTime(new Date(), "yyyy-MM-dd"),

      baseCustomFieldAttr: [],
      genSpecCustomFieldAttr: [],
      analysisCustomFieldAttr: [],
      micTestsCustomFieldAttr: [],
      customField: {
        customKey: "",
        customValue: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
      },
      tableInputCustomFieldAttr: [],
      tableInputField: {
        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: "",
      },
      shelflifeNumber:0,
      addRevNumber:false,
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          approvedBy: "",
          reason:"",
          isComplete:false,
        },
      ],
      typeName:'',
      newNoteArr:[],
      newNote:"",
    };
  },
  methods: {
    back() {
      if(this.$route.query.id){
        if(this.formData.verifiedStatus<=1){
          this.typeName = 'COA'
          this.$router.push({ path: "/cn-coa" });
        }
        else{
          this.typeName = 'Working List'
          this.$router.push({ path: "/cn-worklist" });
        }
      }
      else{
        this.typeName = 'Working List'
        this.$router.push({ path: "/cn-worklist" });
      }
    },
    vertify() {
      this.$confirm("确定去验证吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          if (this.isVerifing) return;
          this.isVerifing = true;
          this.$api.handleCnCoa
            .cnCoaVerified(this.$route.query.id)
            .then((data) => {
              console.log(data);
              this.$message({ type: "success", message: "验证成功！" });
              this.isVerifing = false;
            })
            .catch(() => {
              this.isVerifing = false;
            });
            })
        .catch((action) => {
          if (action == "cancel") {
            // this.verifyChange(false);
          }
        });
    },
    vertifyComplete() {
      if(this.formData.verifiedStatus==2){
        this.$message({
          message: '请先完成COA',
          type: 'warning'
        });
        return false
      }
      this.$confirm("确定要审核吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          if (this.isVerifing) return;
          this.isVerifing = true;
          this.$api.handleCnCoa
            .cnCoaComplete(this.$route.query.id)
            .then((data) => {
              console.log(data);
              this.$message({ type: "success", message: "审核成功！" });
              this.isVerifing = false;
            })
            .catch(() => {
              this.isVerifing = false;
            });
            })
        .catch((action) => {
          if (action == "cancel") {
            // this.verifyChange(false);
          }
        });
    },
    checkSpecificationTotalColiforms(){
      this.form.SpecificationTotalColiform = 
      (this.form.SpecificationTotalColiform.toLowerCase()=='negative' || this.form.SpecificationTotalColiform=='阴性')?
      this.form.SpecificationTotalColiform:
      this.form.SpecificationTotalColiform.replace(/[^\d.]|/g,'')
    },
    updateBySpec(){
      this.$confirm(
        "确定要从Spec同步内容吗?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$api.handleCnSpec
        .getCnDetailByCnItemCode(this.$parent.params.BaseInfoItemCode)
        .then((res) => {
          if (res.jsonValue != undefined) {
            console.log('getCnDetailByCnItemCode==res')
            console.log(res)
            this.$parent.params.BaseInfoProductName = res.productName;
            this.$parent.revDateTime =base.methods.getLocalNowTime();
            let jsonValue = JSON.parse(res.jsonValue)

            this.form.SpecificationColor = jsonValue.color;
            this.form.SpecificationOdor = jsonValue.odor;
            this.form.SpecificationTaste = jsonValue.taste;

            console.log('revNumber==='+jsonValue.revNumber)
            this.$parent.params.revNumber = jsonValue.revNumber;
            console.log('this.$parent.params.revNumber==='+this.$parent.params.revNumber)

            this.getBaseInfoDetail(res)
            this.handleNew('ContinueEdit')
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      });
    },
    handleNew(type) {
      let params = { ...this.$route.query };
      console.log("=============");
      console.log(this.$parent.params);
      this.form.baseInfoCustomField = JSON.stringify(this.$parent.BaseInfoCustomFieldAttr);
      this.form.productType = this.$parent.params.productType;
      this.form.BaseInfoItemCode = this.$parent.params.BaseInfoItemCode;
      this.form.BaseInfoProductName = this.$parent.params.BaseInfoProductName;
      this.form.BaseInfoLot = this.$parent.params.BaseInfoLot;
      this.form.OrderNumber = this.$parent.params.OrderNumber;
      this.form.versionNumber = this.$parent.params.versionNumber;
      this.form.revNumber = this.$parent.params.revNumber;

      this.form.noteTableData = JSON.stringify(this.noteTableData)
      this.form.newNoteArr = JSON.stringify(this.newNoteArr) 

      this.form.GeneralSpecINCIName = this.inclNameArr.join("_");
      this.form.GeneralSpecCommonName = this.commonNameArr.join("_");
      this.form.GeneralSpecBotanicalName = this.botanicalNameArr.join("_");
      this.form.GeneralSpecPartUsed = this.partUsedArr.join("_");

      this.form.baseCustomField = JSON.stringify(this.baseCustomFieldAttr) 
      this.form.genSpecCustomField = JSON.stringify(this.genSpecCustomFieldAttr) 
      this.form.analysisCustomField = JSON.stringify(this.analysisCustomFieldAttr) 
      this.form.micTestsCustomField = JSON.stringify(this.micTestsCustomFieldAttr) 
      this.form.tableInputCustomField = JSON.stringify(this.tableInputCustomFieldAttr) 


      let jsonStr = JSON.stringify(this.form);
      console.log(jsonStr);
      this.formData.productName = this.form.BaseInfoProductName;
      this.formData.itemCode = this.form.BaseInfoItemCode;
      this.formData.lot = this.form.BaseInfoLot;
      this.formData.jsonValue = jsonStr;
      this.formData.createTime = base.methods.formatTime(base.methods.getLocalTime(-7),"yyyy-MM-dd hh:mm:ss");
      console.log(this.formData);
      if (params.id) {
        this.form.revDate.push(this.$parent.revDateTime)
        jsonStr = JSON.stringify(this.form);
        this.formData.jsonValue = jsonStr;
        this.formData.id = params.id;
        this.$api.handleCnCoa
          .editCnCoaUrl(this.formData)
          .then(() => {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            // this.$router.push({
            //   path: "cn-coa",
            // });
            this.reloadPage(type);
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
      } else {
        this.formData.isWorkList = false
        this.$api.handleCnCoa
          .addCnCoaUrl(this.formData)
          .then((res) => {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            console.log("提交返回成功");
            console.log(res);
            this.$confirm("是否要返回列表页?", "Tips", {
              confirmButtonText: "Ok",
              cancelButtonText: "Cancel",
              distinguishCancelAndClose: true,
              type: "warning"
            }).then(() => {
              this.$router.push({
                path: "cn-coa"
              });
            });
            // this.$router.push({
            //   path: "cn-coa",
            // });
            this.reloadPage(type);
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
      }
    },
    reloadPage(type){
      if (type == "Save&New") {
        //清空数据
        this.$router.push({
          path: "cn-coaDetail",
          query: { productType: "TY-P" },
        });
        this.$router.go(0);
      }
    },
    getDetailById(params) {
      this.loading = true;
      this.$api.handleCnCoa.getCnCoaInfo(params).then((data) => {
        console.log(data);
        this.formData = data;
        if(this.formData.verifiedStatus <= 1){
          this.typeName = 'COA'
        }
        else{
          this.typeName = 'Working List'
        }
        console.info('---form value--s');
        this.form = JSON.parse(data.jsonValue);
        console.info(this.form);
        console.info('---form value--e');
        let baseInfoExpirationDate = dayjs(this.form.BaseInfoExpirationDate).add(1,'day').format('YYYY-MM-DD');
        this.form.BaseInfoExpirationDate = baseInfoExpirationDate;
        if(this.form.baseInfoCustomField){
          this.$parent.BaseInfoCustomFieldAttr = JSON.parse(this.form.baseInfoCustomField)
          this.baseInfoCustomFieldList = JSON.parse(this.form.baseInfoCustomField)
        }
        
        if (this.form.BaseInfoManufacturingDate == "NaN-aN-aN") {
          this.form.BaseInfoManufacturingDate = "";
          this.form.BaseInfoExpirationDate = "";
        }
        
        if(this.form.newNoteArr == undefined){
          this.form.newNoteArr = []
          this.newNoteArr = []
        }
        else{
          this.newNoteArr = JSON.parse(this.form.newNoteArr);
        }

        if(this.form.noteTableData){
          this.noteTableData = JSON.parse(this.form.noteTableData);
        }
        if(this.form.revNumber == undefined){
          this.form.revNumber = 0
        }
        if(this.form.revDate == undefined){
          this.form.revDate = []
        }

        // if (this.form.productType == "OilEhxto") {
        //   this.form.productType = "EHXTO";
        // } else if (this.productType == "OilShxto") {
        //   this.form.productType = "SHXTO";
        // }
        this.$parent.params = this.form;
        this.loading = false;

        this.strSplit(this.form.GeneralSpecINCIName, "inclNameArr");
        this.strSplit(this.form.GeneralSpecCommonName, "commonNameArr");
        this.strSplit(this.form.GeneralSpecBotanicalName, "botanicalNameArr");
        this.strSplit(this.form.GeneralSpecPartUsed, "partUsedArr");

        if (this.form.baseCustomField) {
          this.baseCustomFieldAttr=JSON.parse(this.form.baseCustomField)
        }
        if (this.form.genSpecCustomField) {
          this.genSpecCustomFieldAttr=JSON.parse(this.form.genSpecCustomField)
        }
        if (this.form.analysisCustomField) {
          this.analysisCustomFieldAttr=JSON.parse(this.form.analysisCustomField)
        }
        if (this.form.micTestsCustomField) {
          this.micTestsCustomFieldAttr=JSON.parse(this.form.micTestsCustomField)
        }
        if (this.form.tableInputCustomField) {
          this.tableInputCustomFieldAttr=JSON.parse(this.form.tableInputCustomField)
        }
      });
    },
    manufacturingDateChange() {
      // // this.form.BaseInfoManufacturingDate = this.addDate(10,new Date(this.form.BaseInfoManufacturingDate),"day");
      // // let startDate = this.addDate(10,new Date(this.form.BaseInfoManufacturingDate),"day");
      // let startDate = this.form.BaseInfoManufacturingDate;

      // // this.form.BaseInfoExpirationDate = this.addDate(this.shelflifeNumber,new Date(this.form.BaseInfoManufacturingDate),"month");
      // if(this.shelflifeNumber==0){
      //   this.shelflifeNumber=24;
      // }
      // this.form.BaseInfoExpirationDate = dayjs(startDate).add(this.shelflifeNumber,'month');
      
      // console.info('3333-12-1-21-211-2')
      // console.info('this.form.BaseInfoExpirationDate11:',this.form.BaseInfoExpirationDate);

      // this.form.BaseInfoManufacturingDate = this.formatTime(this.form.BaseInfoManufacturingDate,"yyyy-MM-dd");
      // this.form.BaseInfoExpirationDate = this.formatTime(this.form.BaseInfoExpirationDate,"yyyy-MM-dd");
      
      // console.log(this.form.BaseInfoManufacturingDate)
      // console.log(this.form.BaseInfoExpirationDate)

      // this.form.BaseInfoManufacturingDate = this.addDate(10,new Date(this.form.BaseInfoManufacturingDate),"day");
      // let startDate = this.addDate(10,this.form.BaseInfoManufacturingDate,"day");
      let startDate = this.form.BaseInfoManufacturingDate;
      console.info('startDate---:',startDate);

      // this.form.BaseInfoExpirationDate = this.addDate(this.shelflifeNumber,new Date(this.form.BaseInfoManufacturingDate),"month");
      if(this.shelflifeNumber==0){
        this.shelflifeNumber=24;
      }
      this.form.BaseInfoExpirationDate = dayjs(startDate).add(this.shelflifeNumber,'month');
      
      console.info('this.shelflifeNumber----:',this.shelflifeNumber);
      console.info('this.form.BaseInfoExpirationDate55:',this.formatTime(this.form.BaseInfoExpirationDate,"yyyy-MM-dd"));
      // this.form.BaseInfoExpirationDate = this.addDate(10,new Date(this.form.BaseInfoExpirationDate),"day");

      this.form.BaseInfoManufacturingDate = this.formatTime(this.form.BaseInfoManufacturingDate,"yyyy-MM-dd");
      this.form.BaseInfoExpirationDate = this.formatTime(this.form.BaseInfoExpirationDate,"yyyy-MM-dd");
      // this.form.BaseInfoExpirationDate = dayjs(this.form.BaseInfoExpirationDate).add(1,"day");
      
      console.log(this.form.BaseInfoManufacturingDate)
      console.log('aaaa:',this.form.BaseInfoExpirationDate)
    },
    printPage() {
      this.form.BaseInfoItemCode = this.$parent.params.BaseInfoItemCode;
      this.form.BaseInfoProductName = this.$parent.params.BaseInfoProductName;
      this.form.versionNumber = this.$parent.params.versionNumber;
      this.form.BaseInfoLot = this.$parent.params.BaseInfoLot;
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      //console.log(this.formModel);
      var printFileName = "";
      
      if(this.form.BaseInfoProductName != undefined && this.form.BaseInfoProductName != ""){
        printFileName = this.form.BaseInfoProductName.replace(":","~").replace("：", "~");
      }
      if(this.form.BaseInfoLot != undefined && this.form.BaseInfoLot != ""){
        printFileName = printFileName + "_" + this.form.BaseInfoLot;
      }
      printFileName = "(coa)" + printFileName + "_Rev." + this.form.revNumber
      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);

      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    tagHandle(array, tagCotext, index) {
      //tag标签点击事件
      console.info(array, tagCotext, index);
      this.$prompt("修改值-->" + tagCotext, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          array.splice(index, 1, value);
        })
        .catch(() => {});
    }, // ingredients标签操作
    handleClose(tag, arr) {
      this[arr].splice(this[arr].indexOf(tag), 1);
    },
    showInput: function (inputVisible, ref) {
      console.log(inputVisible);
      this[inputVisible] = true;
      this.$nextTick(() => {
        this.$refs[ref].$refs.input.focus();
      });
    },
    handleInputConfirm(value, arr, visible) {
      let inputValue = this[value];
      if (inputValue) {
        console.info(this[arr]);
        this[arr].push(inputValue);
      }

      this[visible] = false;
      this[value] = "";
    },
    strSplit(context, type) {
      this[type] = []; //清理旧有数据防止重复
      if (!context) {
        return;
      }
      let splitArr = context.split("_");
      if (splitArr.length == 1) {
        splitArr = context.split(",");
      }
      splitArr.forEach((v) => {
        if (v.trim().length != 0) {
          this[type].push(v);
        }
      });
    },
    addBaseCustomRow(type, index) {
      if (type == 1) {
        this.baseCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.baseCustomFieldAttr, index);
      }
    },
    addGenSpecCustomRow(type, index) {
      if (type == 1) {
        this.genSpecCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.genSpecCustomFieldAttr, index);
      }
    },
    addAnalysisCustomRow(type, index) {
      if (type == 1) {
        this.analysisCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.analysisCustomFieldAttr, index);
      }
    },
    addMicTestsCustomRow(type, index) {
      if (type == 1) {
        this.micTestsCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.micTestsCustomFieldAttr, index);
      }
    },
    addTableInput(type,index){
      if(type==1){
        this.tableInputCustomFieldAttr.push({ ...this.tableInputField });
      }
      else{
        this.$delete(this.tableInputCustomFieldAttr,index)
      }
    },
    getBaseInfoDetail(newVal){
      var jsonValue=JSON.parse(newVal.jsonValue)
      console.info('---jsonValue----')
      console.log(jsonValue);
      
      if(jsonValue.newNoteArr == undefined){
        this.form.newNoteArr = []
        this.newNoteArr = []
      }
      else{
        this.newNoteArr = JSON.parse(jsonValue.newNoteArr);
      }

      this.shelflifeNumber = jsonValue.shelflifeNumber!= undefined ? parseInt(jsonValue.shelflifeNumber): 36
      console.log("this.shelflifeNumber==="+this.shelflifeNumber)
      this.form.versionNumber = jsonValue.versionNumber;
      this.form.revNumber = jsonValue.revNumber;
      if(jsonValue.countryOfOrigin){
        this.form.BaseInfoCountryofOrigin=jsonValue.countryOfOrigin;
      }

      this.form.GeneralSpecCommonName = jsonValue.commonName;
      this.form.GeneralSpecINCIName = jsonValue.inciName;
      this.form.GeneralSpecBotanicalName = jsonValue.botanicalName;
      this.form.GeneralSpecPartUsed = jsonValue.partUsed;
      this.form.GeneralSpecAppearance = jsonValue.appearance;
      this.form.GeneralSpecSolubility = jsonValue.solubility;
      this.form.GeneralSpecImportantConstituentsInPlant = jsonValue.ehxtoIcip;
      this.form.GeneralSpecHomogeneity = jsonValue.ehxtoHomogeneity;
      this.form.GeneralSpecExtractionProcess = jsonValue.ehxtoExtractionProcess;
      this.form.GeneralSpecCustomRowKey1 = jsonValue.ehxtoCustomerKey1;
      this.form.GeneralSpecCustomRowValue1 = jsonValue.ehxtoCustomerValue1;
      //this.form.GeneralSpecProcessingAid=jsonValue.processingAid;
      this.form.GeneralSpecSolventForExtraction = jsonValue.ehxtoSolventExtraction;
      this.form.GeneralSpecCarrier = jsonValue.ehxtoCarrier;
      this.form.GeneralSpecPesticides = jsonValue.ehxtoPesticides;
      this.form.GeneralSpecCertification = jsonValue.ehxtoCertification;
      this.form.otherIngredients = jsonValue.otherIngredients;

      //#region Analysis
      this.form.SpecificationMoisture = jsonValue.testLimitMoisture;
      this.form.TestMethodMoisture = jsonValue.testMethodMeshSize;
      this.form.SpecificationSpecificGravity = jsonValue.testLimitSpecificaGravity;
      this.form.TestMethodSpecificGravity = jsonValue.testMethodSpecificaGravity;
      this.form.SpecificationRefractiveIndex = jsonValue.testLimitRefractiveIndex;
      this.form.TestMethodRefractiveIndex = jsonValue.testMethodRefractiveIndex;
      this.form.SpecificationOpticalRotation = jsonValue.testLimitOpticalRotation;
      this.form.TestMethodOpticalRotation = jsonValue.testMethodOpticalRotation;
      this.form.SpecificationTotalHeavyMetals = jsonValue.testLimitHeavyMetals;
      this.form.TestMethodTotalHeavyMetals = jsonValue.testMethodHeavyMetals;
      this.form.SpecificationArsenicAS = jsonValue.testLimitArsenic;
      this.form.TestMethodArsenicAS = jsonValue.testMethodArsenic;
      this.form.SpecificationCadmiumCd = jsonValue.testLimitCadmium;
      this.form.TestMethodCadmiumCd = jsonValue.testMethodCadmium;
      this.form.SpecificationLeadPd = jsonValue.testLimitLead;
      this.form.TestMethodLeadPd = jsonValue.testMethodLead;
      this.form.SpecificationMercuryHg = jsonValue.testLimitMercury;
      this.form.TestMethodMercuryHg = jsonValue.testMethodMercury;

      this.form.SpecificationCustomRowKey1 =
        jsonValue.ehxtoTestLimitCustomerKey1;
      this.form.SpecificationCustomRowValue1 =
        jsonValue.ehxtoTestLimitCustomerValue1;
      this.form.TestMethodCustomRowValue1 =
        jsonValue.ehxtoTestMethodCustomerValue1;

      this.form.SpecificationCustomRowKey2 =
        jsonValue.ehxtoTestLimitCustomerKey2;
      this.form.SpecificationCustomRowValue2 =
        jsonValue.ehxtoTestLimitCustomerValue2;
      this.form.TestMethodCustomRowValue2 =
        jsonValue.ehxtoTestMethodCustomerValue2;

      this.form.SpecificationCustomRowKey3 =
        jsonValue.ehxtoTestLimitCustomerKey3;
      this.form.SpecificationCustomRowValue3 =
        jsonValue.ehxtoTestLimitCustomerValue3;
      this.form.TestMethodCustomRowValue3 =
        jsonValue.ehxtoTestMethodCustomerValue3;

      this.form.SpecificationCustomRowKey4 =
        jsonValue.ehxtoTestLimitCustomerKey4;
      this.form.SpecificationCustomRowValue4 =
        jsonValue.ehxtoTestLimitCustomerValue4;
      this.form.TestMethodCustomRowValue4 =
        jsonValue.ehxtoTestMethodCustomerValue4;
      //#endregion

      //#region Microbiological Tests
      this.form.SpecificationTotalPlateCount =
        jsonValue.specificationTotalPlateCount;
      this.form.SpecificationYeastAndMold = jsonValue.specificationYeastAndMold;
      this.form.SpecificationEColi = jsonValue.specificationEcoli;
      this.form.SpecificationSalmonella = jsonValue.specificationSalmonella;
      this.form.SpecificationStaphAureus = jsonValue.specificationStaphAureus;
      this.form.SpecificationTotalColiforms =
        jsonValue.specificationTotalColiforms;

      this.form.TestMethodTotalPlateCount = jsonValue.testMethodTotalPlateCount;
      this.form.TestMethodYeastAndMold = jsonValue.testMethodYeastAndMold;
      this.form.TestMethodEColi = jsonValue.testMethodEcoli;
      this.form.TestMethodSalmonella = jsonValue.testMethodSalmonella;
      this.form.TestMethodStaphAureus = jsonValue.testMethodTaphAureus;
      this.form.TestMethodTotalColiforms = jsonValue.testMethodTotalColiforms;

      this.form.MTSpecificationCustomRowKey1 =
        jsonValue.microbiologicalCustomerKey1;
      this.form.MTSpecificationCustomRowValue1 =
        jsonValue.microbiologicalCustomerValue1;
      this.form.MTTestMethodCustomRowValue1 =
        jsonValue.microbiologicalTestMethodCustomerValue1;
      //#endregion

      this.form.genSpecCustomField = jsonValue.genSpecCustomField;
      this.form.analysisCustomField = jsonValue.analysisCustomField;
      this.form.micTestsCustomField = jsonValue.micTestsCustomField;
      this.form.tableInputCustomField = jsonValue.tableInputCustomField;
      this.form.tableInput1 = jsonValue.tableInput1;
      this.form.tableInput2 = jsonValue.tableInput2;
      this.form.tableInput3 = jsonValue.tableInput3;
      this.form.tableInput4 = jsonValue.tableInput4;
      this.form.tableInput5 = jsonValue.tableInput5;
      
      if (this.form.genSpecCustomField) {
        this.genSpecCustomFieldAttr=JSON.parse(this.form.genSpecCustomField)
      }
      if (this.form.analysisCustomField) {
        this.analysisCustomFieldAttr=JSON.parse(this.form.analysisCustomField)
      }
      if (this.form.micTestsCustomField) {
        this.micTestsCustomFieldAttr=JSON.parse(this.form.micTestsCustomField)
      }
      if (this.form.tableInputCustomField) {
        this.tableInputCustomFieldAttr=JSON.parse(this.form.tableInputCustomField)
      }

      this.strSplit(this.form.GeneralSpecINCIName, "inclNameArr");
      this.strSplit(this.form.GeneralSpecCommonName, "commonNameArr");
      this.strSplit(this.form.GeneralSpecBotanicalName, "botanicalNameArr");
      this.strSplit(this.form.GeneralSpecPartUsed, "partUsedArr");
      console.log(this.form);
      console.log("=======OilEhxto-baseInfoItemCodeDetail-end=========");
    },
    addNewNoteTableClick(){
      this.newNoteArr.push({
        content:this.newNote
      })
      this.newNote = ''
    },
    deleteNewNoteTableClick(index){
      this.newNoteArr.splice(index,1);
      // this.$delete(this.newNoteArr,index)
    },
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    this.formData.updateName = userInfo.username
  },
  mounted() {
    let params = { ...this.$route.query };
    if (params.id) {
      this.showVerified = true;
      this.getDetailById(params.id);
    }
    else{
      this.typeName = 'Working List'
    }
    let localData = localStorage.getItem("tpsDataKey");
    let data;
    if (localData != "undefined") {
      data = JSON.parse(localStorage.getItem("tpsDataKey"));
    }

    if (data) {
      Object.keys(data).forEach((key) => {
        this.form[key] = data[key];
      });
    }
  },
  watch: {
    baseInfoItemCodeDetail: {
      handler(newVal) {
        console.log("=======OilEhxto-baseInfoItemCodeDetail-start=========");
        console.log(newVal); //GeneralSpecCustomRowKey1
        this.baseInfoCustomFieldList = newVal.BaseInfoCustomFieldAttr
        if(newVal.BaseInfoItemCodeDetail.jsonValue){
          console.info('newVal.BaseInfoItemCodeDetail=====')
          console.info(newVal.BaseInfoItemCodeDetail);
          this.getBaseInfoDetail(newVal.BaseInfoItemCodeDetail)
        }
        else{
          console.log('空数组')
        }
      },
      //为了发现内部的内容发生了变化把状态改变成true
      deep: true,
      immediate: true,//首次赋值的时候也会触发watch里面的handler事件
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
</style>
<style>
@import "../module/CoaStyle.css";
</style>


