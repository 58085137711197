<template>
  <div class="page" style="margin-top: -80px">
    <center>
      <div class="new-item-content">
        <div id="printDiv" v-show="printShow">
          <COAOilShxto
            :printModel="this.$data"
            style="height-max: 1000px; width: 100vw"
          ></COAOilShxto>
        </div>
        <!-- Base Info -->
        <div class="base-info">
          <div></div>
          <div>
            <div>
              <span>编辑打印标题：</span>

              <div class="el-input" style="text-align: left">
                <el-checkbox
                  v-model="form.BaseInfoEditPrintTitleCheckbox"
                  placeholder="请输入"
                ></el-checkbox>
                <el-input
                  v-show="form.BaseInfoEditPrintTitleCheckbox"
                  v-model="form.BaseInfoEditPrintTitle"
                  placeholder="请输入"
                  style="margin-left: 10px; width: 575px"
                ></el-input>
              </div>
            </div>
            <div>
              <span>原产地：</span>
              <el-input
                v-model="form.BaseInfoCountryofOrigin"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>生产日期：</span>
              <el-date-picker
                v-model="form.BaseInfoManufacturingDate"
                placeholder="Please Select Date"
                type="date"
                @change="manufacturingDateChange"
              ></el-date-picker>
            </div>
            <div>
              <span>有效期：</span>
              <el-date-picker
                v-model="form.BaseInfoExpirationDate"
                placeholder="Please Select Date"
                type="date"
              ></el-date-picker>
            </div>
            <!-- <div>
              <span style="margin-left: 46px">
                <el-input
                  v-model="form.BaseInfoCustomRowKey1"
                  class="custom-row"
                  placeholder="请输入名称"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.BaseInfoCustomRowValue1"
                  class="custom-row"
                  placeholder="请输入值"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addBaseCustomRow(1, 0)"
                >+</el-button>
            </div>
            
            <div
              v-for="(item, baseIndex) in baseCustomFieldAttr"
              :key="baseIndex + 1000"
            >
              <span style="margin-left: 96px">
                <el-input
                  v-model="item.customKey"
                  placeholder="请输入名称"
                  class="custom-row"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="item.customValue"
                  class="custom-row"
                  placeholder="请输入值"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addBaseCustomRow(1, baseIndex)"
                >+</el-button
              >
              <el-button
                type="danger"
                class="button-new-tag"
                size="small"
                @click="addBaseCustomRow(0, baseIndex)"
                >-</el-button
              >
            </div> -->
          </div>
        </div>
        <!-- General Specification -->
        <div class="base-info">
          <div>标准规格</div>
          <div>
            <div style="height: auto">
              <span>通用名/其他名称：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in commonNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(commonNameArr, tag, i)"
                    @close="handleClose(tag, 'commonNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="commonNameInputVisible"
                    v-model="commonNameInputValue"
                    ref="commonNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'commonNameInputValue',
                        'commonNameArr',
                        'commonNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="commonNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'commonNameInputVisible',
                        'commonNameSaveTagInput'
                      )
                    "
                    >+ 添加通用名/其他名称</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>INCI名称：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in inclNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(inclNameArr, tag, i)"
                    @close="handleClose(tag, 'inclNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="inclNameInputVisible"
                    v-model="inclNameInputValue"
                    ref="inclNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'inclNameInputValue',
                        'inclNameArr',
                        'inclNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="inciNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('inclNameInputVisible', 'inclNameSaveTagInput')
                    "
                    >+ 添加INCI名称</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>植物学名：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in botanicalNameArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(botanicalNameArr, tag, i)"
                    @close="handleClose(tag, 'botanicalNameArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="botanicalNameInputVisible"
                    v-model="botanicalNameInputValue"
                    ref="botanicalNameSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'botanicalNameInputValue',
                        'botanicalNameArr',
                        'botanicalNameInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="botanicalNameD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput(
                        'botanicalNameInputVisible',
                        'botanicalNameSaveTagInput'
                      )
                    "
                    >+ 添加植物学名</el-button
                  >
                </div>
              </div>
            </div>
            <div style="height: auto">
              <span>使用部位：</span>
              <div
                style="display: inline-block; width: 615px; text-align: left"
              >
                <div
                  style="
                    display: flex;
                    justify: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in partUsedArr"
                    closable
                    :disable-transitions="false"
                    @click="tagHandle(partUsedArr, tag, i)"
                    @close="handleClose(tag, 'partUsedArr')"
                    >{{ tag }}</el-tag
                  >
                  <el-input
                    class="input-new-tag"
                    v-if="partUsedInputVisible"
                    v-model="partUsedInputValue"
                    ref="partUsedSaveTagInput"
                    size="small"
                    @keyup.enter.native="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                    @blur="
                      handleInputConfirm(
                        'partUsedInputValue',
                        'partUsedArr',
                        'partUsedInputVisible'
                      )
                    "
                  ></el-input>
                  <el-button
                    :disabled="partUsedD"
                    v-else
                    class="button-new-tag"
                    size="small"
                    @click="
                      showInput('partUsedInputVisible', 'partUsedSaveTagInput')
                    "
                    >+ 添加使用部位</el-button
                  >
                </div>
              </div>
            </div>
            
          <div style="height: auto">
            <div style="display: inline-block; text-align: left">
            <table style="border: 1px solid #eee;">
              <tr>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput1"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput2"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput3"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput4"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="form.tableInput5"></el-input>
                </td>
                <td>
                  <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                </td>
              </tr>
              <tr v-for="(item, tableInputIndex) in tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput1"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput2"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput3"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput4"></el-input>
                </td>
                <td style="border: 1px solid #eee;">
                  <el-input placeholder="请输入内容" style="width: 100%" v-model="item.tableInput5"></el-input>
                </td>
                <td>
                  <el-button type="primary" class="button-new-tag" size="small" @click="addTableInput(1, 0)">+</el-button>
                  <el-button type="danger" class="button-new-tag" size="small" @click="addTableInput(0, tableInputIndex)">-</el-button>
                </td>
              </tr>
            </table>
            </div>
          </div>
            <div>
              <span>外观：</span>
              <el-input
                v-model="form.GeneralSpecAppearance"
                placeholder="请输入"
              ></el-input>
            </div>
            <div>
              <span>其他成分：</span>
              <el-input
                v-model="form.otherIngredients"
                class="custom-row"
                placeholder="请输入其他成分"
              ></el-input>
            </div>
            <div>
              <span
                ><el-input
                  v-model="form.GeneralSpecCustomRowKey1"
                  class="custom-row"
                  placeholder="请输入"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue1"
                  class="custom-row"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
            <div>
              <span
                ><el-input
                  v-model="form.GeneralSpecCustomRowKey2"
                  class="custom-row"
                  placeholder="请输入"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue2"
                  class="custom-row"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>

            <div>
              <span  style="margin-left: 48px">
                <el-input
                  v-model="form.GeneralSpecCustomRowKey3"
                  class="custom-row"
                  placeholder="请输入"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="form.GeneralSpecCustomRowValue3"
                  class="custom-row"
                  placeholder="请输入"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, 0)"
                >+</el-button>
            </div>

            <div
              v-for="(item, genSpecIndex) in genSpecCustomFieldAttr"
              :key="genSpecIndex + 1"
            >
              <span style="margin-left: 96px">
                <el-input
                  v-model="item.customKey"
                  placeholder="Please Name Input"
                  class="custom-row"
                  style="width: 50%"
                ></el-input
                >：</span
              >
              <div class="custom-row el-input">
                <el-input
                  v-model="item.customValue"
                  class="custom-row"
                  placeholder="Please Value Input"
                ></el-input>
              </div>
              <el-button
                type="primary"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(1, genSpecIndex)"
                >+</el-button
              >
              <el-button
                type="danger"
                class="button-new-tag"
                size="small"
                @click="addGenSpecCustomRow(0, genSpecIndex)"
                >-</el-button
              >
            </div>

          </div>
        </div>
        <!-- Analysis -->
        <div class="base-info">
          <div>分析</div>
          <div>
            <table
              class="AnalysisTable"
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td style="width: 25%" class="FontWeight">检测项目</td>
                <td style="width: 25%" class="FontWeight">标准</td>
                <td style="width: 25%" class="FontWeight">结果</td>
                <td style="width: 25%" class="FontWeight">测试方法</td>
              </tr>

              <tr>
                <td class="FontWeight">酸价</td>
                <td>
                  <el-input
                v-model="form.SpecificationAcidValue"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="prepend">
                  <div style="width: 100%">NMT</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">mg KOH/G</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultAcidValue"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodAcidValue"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">过氧化物值</td>
                <td>
                  <el-input
                v-model="form.SpecificationPeroxideValue"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="prepend">
                  <div style="width: 100%">NMT</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">meq/kg</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultPeroxideValue"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodPeroxideValue"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">皂化值</td>
                <td>
                  <el-input
                v-model="form.SpecificationSaponificationValue"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">mg KOH/G</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultSaponificationValue"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodSaponificationValue"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">不皂化物含量</td>
                <td>
                  <el-input
                v-model="form.SpecificationUnsaponifiableMatter"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultUnsaponifiableMatter"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodUnsaponifiableMatter"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">水分和挥发物</td>
                <td>
                  <el-input
                v-model="form.SpecificationMoistureAndVolatiles"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="prepend">
                  <div style="width: 100%">NMT</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultMoistureAndVolatiles"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodMoistureAndVolatiles"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">总重金属</td>
                <td>
                  <el-input
                v-model="form.SpecificationTotalHeavyMetals"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="prepend">
                  <div style="width: 100%">&lt;</div>
                </template>
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalHeavyMetals"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                  <template slot="append">
                    <div style="width: 100%">ppm</div>
                  </template>
                </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalHeavyMetals"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">砷</td>
                <td>
                  <el-input
                v-model="form.SpecificationArsenic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultArsenic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodArsenic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">镉</td>
                <td>
                  <el-input
                v-model="form.SpecificationCadmium"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCadmium"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCadmium"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">铅</td>
                <td>
                  <el-input
                v-model="form.SpecificationLeadPd"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultLead"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodLead"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">汞</td>
                <td>
                  <el-input
                v-model="form.SpecificationMercury"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">ppm</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultMercury"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodMercury"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">农药残留</td>
                <td>
                  <el-input
                    v-model="form.SpecificationPesticides"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultPesticides"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodPesticides"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">颜色</td>
                <td>
                  <el-input
                    v-model="form.SpecificationColor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultColor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodColor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">气味</td>
                <td>
                  <el-input
                    v-model="form.SpecificationOdor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultOdor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodOdor"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">味道</td>
                <td>
                  <el-input
                    v-model="form.SpecificationTaste"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTaste"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTaste"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.SpecificationCustomRowKey2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.SpecificationCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, 0)"
                  >+</el-button>
              </tr>
              
              <tr
                v-for="(item, analysisIndexEhxto) in analysisCustomFieldAttr"
                :key="analysisIndexEhxto"
              >
                <td class="FontWeight">
                  <el-input
                    v-model="item.customKey"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <el-button
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(1, analysisIndexEhxto)"
                  >+</el-button
                >
                <el-button
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addAnalysisCustomRow(0, analysisIndexEhxto)"
                  >-</el-button
                >
              </tr>
            </table>
          </div>
        </div>
        <!-- Fatty Acid Composition -->
        <div class="base-info">
          <div>脂肪酸组成</div>
          <div>
            <table
              class="AnalysisTable"
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td style="width: 25%" class="FontWeight">检测项目</td>
                <td style="width: 25%" class="FontWeight">标准</td>
                <td style="width: 25%" class="FontWeight">结果</td>
                <td style="width: 25%" class="FontWeight">测试方法</td>
              </tr>
              <tr>
                <td class="FontWeight">C8:0辛酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationC80Caprylic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultC80Caprylic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodC80Caprylic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C10:0癸酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationC100Capric"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultC100Capric"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodC100Capric"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C12:0月桂酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationLauric"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultLauric"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodLauric"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C14:0豆蔻酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationMyristic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultMyristic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodMyristic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C14:1豆蔻油酸 </td>
                <td>
                  <el-input
                v-model="form.SpecificationMyristoleic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultMyristoleic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodMyristoleic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">C16:0棕榈酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationC160Palmitic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultC160Palmitic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodC160Palmitic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C16:1棕榈油酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationC161Palmitoleic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultC161Palmitoleic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodC161Palmitoleic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C18:0硬脂酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationC180Stearic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultC180Stearic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodC180Stearic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C18:1油酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationC181Oleic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultC181Oleic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodC181Oleic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C18:2亚油酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationC182Linoleic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultC182Linoleic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodC182Linoleic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C18:3 α-亚麻酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationC183αLinolenic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultC183αLinolenic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodC183αLinolenic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C18:3 γ-亚麻酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationC183γLinoleicAcid"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultC183γLinoleicAcid"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodC183γLinoleicAcid"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C18:3 石榴酸</td>
                <td>
                  <el-input
                v-model="form.SpecificationC183PunicicAcid"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultC183PunicicAcid"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodC183PunicicAcid"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              
              <tr>
                <td class="FontWeight">C20:0 花生酸  </td>
                <td>
                  <el-input
                v-model="form.SpecificationArachidic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultArachidic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodArachidic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C20:4 花生四烯酸 </td>
                <td>
                  <el-input
                v-model="form.SpecificationArachidonic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultArachidonic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodArachidonic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C20:5 二十碳五烯酸 </td>
                <td>
                  <el-input
                v-model="form.SpecificationEicosapentaenoic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultEicosapentaenoic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodEicosapentaenoic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">C22:6 二十二碳六烯酸 </td>
                <td>
                  <el-input
                v-model="form.SpecificationDocosahexaenoic"
                placeholder="请输入"
                    class="InputWidth"
              >
                <template slot="append">
                  <div style="width: 100%">%</div>
                </template>
              </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultDocosahexaenoic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodDocosahexaenoic"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.MTSpecificationCustomRowKey1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTSpecificationCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTResultCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTTestMethodCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.MTSpecificationCustomRowKey2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTSpecificationCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTResultCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTTestMethodCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.MTSpecificationCustomRowKey3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTSpecificationCustomRowValue3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTResultCustomRowValue3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MTTestMethodCustomRowValue3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addFattyCustomRow(1, 0)"
                  >+</el-button>
              </tr>
              
              <tr
                v-for="(item, fattyIndex) in fattyCustomFieldAttr"
                :key="fattyIndex"
              >
                <td class="FontWeight">
                  <el-input
                    v-model="item.customKey"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <el-button
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addFattyCustomRow(1, fattyIndex)"
                  >+</el-button
                >
                <el-button
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addFattyCustomRow(0, fattyIndex)"
                  >-</el-button
                >
              </tr>

              <tr>
                <td class="FontWeight">其他</td>
                <td>
                  <el-input
                    v-model="form.SpecificationOthers"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">%</div>
                    </template></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultOthers"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodOthers"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- Microbiological Tests -->
        <div class="base-info">
          <div>微生物检验</div>
          <div>
            <table
              class="AnalysisTable"
              border="1"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td style="width: 25%" class="FontWeight">检测项目</td>
                <td style="width: 25%" class="FontWeight">标准</td>
                <td style="width: 25%" class="FontWeight">结果</td>
                <td style="width: 25%" class="FontWeight">测试方法 </td>
              </tr>
              <tr>
                <td class="FontWeight">细菌总数</td>
                <td>
                  <el-input
                    v-model="form.SpecificationTotalPlateCount"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalPlateCount"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalPlateCount"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">酵母和霉菌</td>
                <td>
                  <el-input
                    v-model="form.SpecificationYeastAndMold"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultYeastAndMold"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="append">
                      <div style="width: 100%">cfu / g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodYeastAndMold"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">大肠杆菌</td>
                <td>
                  <el-input
                    v-model="form.SpecificationEcoli"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultEcoli"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodEcoli"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">沙门氏菌</td>
                <td>
                  <el-input
                    v-model="form.SpecificationSalmonella"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultSalmonella"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodSalmonella"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">金色葡萄球菌</td>
                <td>
                  <el-input
                    v-model="form.SpecificationStaphAureus"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultStaphAureus"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodStaphAureus"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">大肠菌群</td>
                <td>
                  <el-input
                  @blur="checkSpecificationTotalColiforms()"
                    v-model="form.SpecificationTotalColiforms"
                    placeholder="请输入"
                    class="InputWidth"
                  >
                    <template slot="prepend">
                      <div style="width: 100%">&lt;</div>
                    </template>
                    <template slot="append">
                      <div style="width: 100%">MPN/g</div>
                    </template>
                  </el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.ResultTotalColiforms"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.TestMethodTotalColiforms"
                    placeholder="请输入"
                    class="InputWidth"
                  ></el-input>
                </td>
              </tr>

              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.MicTestsCustomRowKey1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MicTestsSpecificationCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MicTestsResultCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MicTestsTestMethodCustomRowValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <el-button
                  style="margin-top: 14px"
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, 0)"
                  >+</el-button>
              </tr>
              
              <tr
                v-for="(item, micTestsIndex) in micTestsCustomFieldAttr"
                :key="micTestsIndex"
              >
                <td class="FontWeight">
                  <el-input
                    v-model="item.customKey"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="item.customValue1"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <el-button
                  type="primary"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(1, micTestsIndex)"
                  >+</el-button
                >
                <el-button
                  type="danger"
                  class="button-new-tag"
                  size="small"
                  @click="addMicTestsCustomRow(0, micTestsIndex)"
                  >-</el-button
                >
              </tr>

              <!-- <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.MicTestsCustomRowKey2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MicTestsSpecificationCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MicTestsResultCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MicTestsTestMethodCustomRowValue2"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="FontWeight">
                  <el-input
                    v-model="form.MicTestsCustomRowKey3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MicTestsSpecificationCustomRowValue3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MicTestsResultCustomRowValue3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    v-model="form.MicTestsTestMethodCustomRowValue3"
                    class="InputWidth"
                    placeholder="请输入"
                  ></el-input>
                </td>
              </tr> -->
            </table>
          </div>
        </div>
        <NoteTableData :baseInfo="this.$data"></NoteTableData>
        <div class="other-notes">
          <div>Notes</div>
          <div style="margin-top:0px;">
            <el-input v-model="newNote" placeholder="Please Input" style="width:600px;margin-bottom:10px;margin-right:10px;"></el-input>
            <el-button type="text" @click="addNewNoteTableClick">
              Complete
            </el-button>
          </div>
        </div>
        <div class="base-info">
          <div>注释:</div>
          <div style="width: 80%; text-align: left; font-weight: bold">
            <div>
              * 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。
            </div>
            <div>
              * 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。
            </div>
            <div>
              * 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。
            </div>
            <div v-for="(item, index) in newNoteArr" :key="index" style="margin-top:0px;">
              * {{item.content}}
              <el-button type="text" @click="deleteNewNoteTableClick(index)">
                <i style="color:red;font-size:20px;" class="el-icon-delete"></i>
              </el-button>
            </div>
          </div>
        </div>
        <!-- Save -->
        <div class="save-bottom">
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('Continue Edit')">新增至 Working List</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew('Continue Edit')">保存 CoA</el-button>
          <el-button v-if="userInfo.operateStatus" type="success" @click="vertify" :icon="isVerifing ? 'el-icon-loading' : ''">完成</el-button>
          <el-button v-if="userInfo.operateStatus" type="danger"  @click="vertifyComplete" :icon="isVerifing ? 'el-icon-loading' : ''">审核</el-button>
          <el-button v-if="userInfo.operateStatus" type="primary" @click="updateBySpec()">更新COA</el-button>
          <el-button @click="back">返回</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printPage()">打印</el-button>
          <!-- <el-button type="primary" @click="handleNew('ContinueEdit')">保存{{typeName}}</el-button>
          <el-button type="primary" @click="handleNew('Save&New')">保存并新增{{typeName}}</el-button>
          <el-button type="primary" @click="updateBySpec()">更新COA</el-button>
          <el-button type="warning" 
          :icon="isVerifing ? 'el-icon-loading' : ''" 
          v-if="showVerified && pemission(userInfo.username)" 
          @click="vertify" >Vertify</el-button>
          <el-button @click="back">返回 {{typeName}}</el-button>
          <el-button type="success" icon="el-icon-printer" @click="printPage()">打印</el-button> -->
        </div>
      </div>
    </center>
  </div>
</template>
<script>
var dayjs = require('dayjs');
import base from "@/common/base.js";
import printJS from "print-js";
import COAOilShxto from "../PrintProductTypeModule/Cn-COA-OilShxto";
import NoteTableData from "./NoteTableData";
export default {
  props: { baseInfoItemCodeDetail: {} },
  mixins: [base],
  components: { COAOilShxto,NoteTableData },
  data() {
    return {
      isVerifing: false,
      showVerified:false,
      inclNameArr: [],
      inclNameInputVisible: "",
      inclNameInputValue: "",
      inciNameD: false,

      commonNameArr: [],
      commonNameInputVisible: "",
      commonNameInputValue: "",
      commonNameD: false,

      botanicalNameArr: [],
      botanicalNameInputVisible: "",
      botanicalNameInputValue: "",
      botanicalNameD: false,

      partUsedArr: [],
      partUsedInputVisible: "",
      partUsedInputValue: "",
      partUsedD: false,

      printShow: false,
      baseInfoCustomFieldList:[],
      form: {
        baseInfoCustomField:"",
        revNumber:1,
        revDate:[],
        noteTableData:"",
        versionNumber:"",
        BaseInfoEditPrintTitle: "Draco Natural Products, Inc.",
        BaseInfoEditPrintTitleCheckbox: false,
        productType: "",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        BaseInfoLot: "",
        OrderNumber:"",
        BaseInfoCountryofOrigin: "中国",

        BaseInfoManufacturingDate: "",
        BaseInfoExpirationDate: "",
        BaseInfoCustomRowKey1: "",
        BaseInfoCustomRowValue1: "",

        GeneralSpecCommonName: "",
        GeneralSpecINCIName: "",
        GeneralSpecBotanicalName: "",
        GeneralSpecPartUsed: "",
        GeneralSpecAppearance: "",
        otherIngredients: "",

        GeneralSpecCustomRowKey1: "",
        GeneralSpecCustomRowValue1: "",
        GeneralSpecCustomRowKey2: "",
        GeneralSpecCustomRowValue2: "",
        GeneralSpecCustomRowKey3: "",
        GeneralSpecCustomRowValue3: "",

        SpecificationAcidValue: "",
        SpecificationPeroxideValue: "",
        SpecificationSaponificationValue: "",
        SpecificationUnsaponifiableMatter: "",
        SpecificationMoistureAndVolatiles: "",
        SpecificationTotalHeavyMetals: "",
        SpecificationArsenic: "",
        SpecificationCadmium: "",
        SpecificationLead: "",
        SpecificationMercury: "",
        SpecificationPesticides: "",
        SpecificationColor: "",
        SpecificationOdor: "符合标准",
        SpecificationTaste: "符合标准",

        ResultAcidValue: "",
        ResultPeroxideValue: "",
        ResultSaponificationValue: "",
        ResultUnsaponifiableMatter: "",
        ResultMoistureAndVolatiles: "",
        ResultTotalHeavyMetals: "<10",
        ResultArsenic: "",
        ResultCadmium: "",
        ResultLead: "",
        ResultMercury: "",
        ResultPesticides: "符合",
        ResultColor: "符合标准",
        ResultOdor: "符合标准",
        ResultTaste: "符合标准",

        TestMethodAcidValue: "",
        TestMethodPeroxideValue: "",
        TestMethodSaponificationValue: "",
        TestMethodUnsaponifiableMatter: "",
        TestMethodMoistureAndVolatiles: "",
        TestMethodTotalHeavyMetals: "USP <231>",
        TestMethodArsenic: "",
        TestMethodCadmium: "",
        TestMethodLead: "",
        TestMethodMercury: "",
        TestMethodPesticides: "",
        TestMethodColor: "视觉",
        TestMethodOdor: "感官评定",
        TestMethodTaste: "感官评定",

        SpecificationC80Caprylic: "",
        SpecificationC100Capric: "",
        SpecificationC160Palmitic: "",
        SpecificationC161Palmitoleic: "",
        SpecificationC180Stearic: "",
        SpecificationC181Oleic: "",
        SpecificationC182Linoleic: "",
        SpecificationC183αLinolenic: "",
        SpecificationC183γLinoleicAcid: "",
        SpecificationC183PunicicAcid: "",
        SpecificationOthers: "",

        ResultC80Caprylic: "",
        ResultC100Capric: "",
        ResultC160Palmitic: "",
        ResultC161Palmitoleic: "",
        ResultC180Stearic: "",
        ResultC181Oleic: "",
        ResultC182Linoleic: "",
        ResultC183αLinolenic: "",
        ResultC183γLinoleicAcid: "",
        ResultC183PunicicAcid: "",
        ResultOthers: "",

        TestMethodC80Caprylic: "",
        TestMethodC100Capric: "",
        TestMethodC160Palmitic: "",
        TestMethodC161Palmitoleic: "",
        TestMethodC180Stearic: "",
        TestMethodC181Oleic: "",
        TestMethodC182Linoleic: "",
        TestMethodC183αLinolenic: "",
        TestMethodC183γLinoleicAcid: "",
        TestMethodC183PunicicAcid: "",
        TestMethodOthers: "",
        
        SpecificationLauric: "",
        ResultLauric: "",
        TestMethodLauric: "",
        SpecificationMyristic: "",
        ResultMyristic: "",
        TestMethodMyristic: "",
        SpecificationMyristoleic: "",
        ResultMyristoleic: "",
        TestMethodMyristoleic: "",
        SpecificationArachidic: "",
        ResultArachidic: "",
        TestMethodArachidic: "",
        SpecificationArachidonic: "",
        ResultArachidonic: "",
        TestMethodArachidonic: "",
        SpecificationEicosapentaenoic: "",
        ResultEicosapentaenoic: "",
        TestMethodEicosapentaenoic: "",
        SpecificationDocosahexaenoic: "",
        ResultDocosahexaenoic: "",
        TestMethodDocosahexaenoic: "",

        SpecificationCustomRowKey1: "",
        SpecificationCustomRowValue1: "",
        ResultCustomRowKey1: "",
        ResultCustomRowValue1: "",
        TestMethodCustomRowKey1: "",
        TestMethodCustomRowValue1: "",

        SpecificationCustomRowKey2: "",
        SpecificationCustomRowValue2: "",
        ResultCustomRowKey2: "",
        ResultCustomRowValue2: "",
        TestMethodCustomRowKey2: "",
        TestMethodCustomRowValue2: "",

        SpecificationCustomRowKey3: "",
        SpecificationCustomRowValue3: "",
        ResultCustomRowKey3: "",
        ResultCustomRowValue3: "",
        TestMethodCustomRowKey3: "",
        TestMethodCustomRowValue3: "",

        MTSpecificationCustomRowKey1: "",
        MTSpecificationCustomRowValue1: "",
        MTResultCustomRowKey1: "",
        MTResultCustomRowValue1: "",
        MTTestMethodCustomRowKey1: "",
        MTTestMethodCustomRowValue1: "",

        MTSpecificationCustomRowKey2: "",
        MTSpecificationCustomRowValue2: "",
        MTResultCustomRowKey2: "",
        MTResultCustomRowValue2: "",
        MTTestMethodCustomRowKey2: "",
        MTTestMethodCustomRowValue2: "",

        MTSpecificationCustomRowKey3: "",
        MTSpecificationCustomRowValue3: "",
        MTResultCustomRowKey3: "",
        MTResultCustomRowValue3: "",
        MTTestMethodCustomRowKey3: "",
        MTTestMethodCustomRowValue3: "",

        SpecificationTotalPlateCount: "",
        SpecificationYeastAndMold: "",
        SpecificationEcoli: "",
        SpecificationSalmonella: "",
        SpecificationStaphAureus: "",
        SpecificationTotalColiforms: "",

        ResultTotalPlateCount: "",
        ResultYeastAndMold: "",
        ResultEcoli: "",
        ResultSalmonella: "",
        ResultStaphAureus: "",
        ResultTotalColiforms: "",

        TestMethodTotalPlateCount: "FDA-BAM",
        TestMethodYeastAndMold: "FDA-BAM",
        TestMethodEcoli: "FDA-BAM",
        TestMethodSalmonella: "FDA-BAM",
        TestMethodStaphAureus: "FDA-BAM",
        TestMethodTotalColiforms: "FDA-BAM",

        MicTestsCustomRowKey1: "",
        MicTestsSpecificationCustomRowValue1: "",
        MicTestsResultCustomRowValue1: "",
        MicTestsTestMethodCustomRowValue1: "",

        MicTestsCustomRowKey2: "",
        MicTestsSpecificationCustomRowValue2: "",
        MicTestsResultCustomRowValue2: "",
        MicTestsTestMethodCustomRowValue2: "",

        MicTestsCustomRowKey3: "",
        MicTestsSpecificationCustomRowValue3: "",
        MicTestsResultCustomRowValue3: "",
        MicTestsTestMethodCustomRowValue3: "",
        
        baseCustomField: "",
        genSpecCustomField: "",
        analysisCustomField: "",
        fattyCustomField: "",
        micTestsCustomField: "",

        tableInput1:"",
        tableInput2:"",
        tableInput3:"",
        tableInput4:"",
        tableInput5:"",
        tableInputCustomField:"",
      },
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        lot: "",
        updateName:"",
        createTime:base.methods.formatTime(base.methods.getLocalTime(-7),"yyyy-MM-dd hh:mm:ss"),
        jsonValue: {},
      },
      createTime: this.formatTime(new Date(),"yyyy-MM-dd"),
      
      baseCustomFieldAttr: [],
      genSpecCustomFieldAttr: [],
      analysisCustomFieldAttr: [],
      fattyCustomFieldAttr:[],
      micTestsCustomFieldAttr:[],
      customField: {
        customKey: "",
        customValue: "",
        customKey1: "",
        customValue1: "",
        customKey2: "",
        customValue2: "",
      },
      tableInputCustomFieldAttr: [],
      tableInputField: {
        tableInput1: "",
        tableInput2: "",
        tableInput3: "",
        tableInput4: "",
        tableInput5: "",
      },
      shelflifeNumber:0,
      addRevNumber:false,
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          approvedBy: "",
          reason:"",
          isComplete:false,
        },
      ],
      typeName:'',
      newNoteArr:[],
      newNote:"",
    };
  },
  methods: {
    back() {
      if(this.$route.query.id){
        if(this.formData.verifiedStatus<=1){
          this.typeName = 'COA'
          this.$router.push({ path: "/cn-coa" });
        }
        else{
          this.typeName = 'Working List'
          this.$router.push({ path: "/cn-worklist" });
        }
      }
      else{
        this.typeName = 'Working List'
        this.$router.push({ path: "/cn-worklist" });
      }
    },
    vertify() {
      this.$confirm("确定去验证吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          if (this.isVerifing) return;
          this.isVerifing = true;
          this.$api.handleCnCoa
            .cnCoaVerified(this.$route.query.id)
            .then((data) => {
              console.log(data);
              this.$message({ type: "success", message: "验证成功！" });
              this.isVerifing = false;
            })
            .catch(() => {
              this.isVerifing = false;
            });
            })
        .catch((action) => {
          if (action == "cancel") {
            // this.verifyChange(false);
          }
        });
    },
    vertifyComplete() {
      if(this.formData.verifiedStatus==2){
        this.$message({
          message: '请先完成COA',
          type: 'warning'
        });
        return false
      }
      this.$confirm("确定要审核吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          if (this.isVerifing) return;
          this.isVerifing = true;
          this.$api.handleCnCoa
            .cnCoaComplete(this.$route.query.id)
            .then((data) => {
              console.log(data);
              this.$message({ type: "success", message: "审核成功！" });
              this.isVerifing = false;
            })
            .catch(() => {
              this.isVerifing = false;
            });
            })
        .catch((action) => {
          if (action == "cancel") {
            // this.verifyChange(false);
          }
        });
    },
    checkSpecificationTotalColiforms(){
      this.form.SpecificationTotalColiform = 
      (this.form.SpecificationTotalColiform.toLowerCase()=='negative' || this.form.SpecificationTotalColiform=='阴性')?
      this.form.SpecificationTotalColiform:
      this.form.SpecificationTotalColiform.replace(/[^\d.]|/g,'')
    },
    updateBySpec(){
      this.$confirm(
        "确定要从Spec同步内容吗?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.$api.handleCnSpec
        .getCnDetailByCnItemCode(this.$parent.params.BaseInfoItemCode)
        .then((res) => {
          if (res.jsonValue != undefined) {
            console.log('getCnDetailByCnItemCode==res')
            console.log(res)
            this.$parent.params.BaseInfoProductName = res.productName;
            this.$parent.revDateTime =base.methods.getLocalNowTime();
            let jsonValue = JSON.parse(res.jsonValue)
            console.log('revNumber==='+jsonValue.revNumber)
            this.$parent.params.revNumber = jsonValue.revNumber;
            console.log('this.$parent.params.revNumber==='+this.$parent.params.revNumber)

            this.getBaseInfoDetail(res)
            this.handleNew('ContinueEdit')
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      });
    },
    handleNew(type) {
      let params = { ...this.$route.query };
      console.log("=============");
      console.log(this.$parent.params);
      this.form.baseInfoCustomField = JSON.stringify(this.$parent.BaseInfoCustomFieldAttr);
      this.form.productType = this.$parent.params.productType;
      this.form.BaseInfoItemCode = this.$parent.params.BaseInfoItemCode;
      this.form.BaseInfoProductName = this.$parent.params.BaseInfoProductName;
      this.form.BaseInfoLot = this.$parent.params.BaseInfoLot;
      this.form.OrderNumber = this.$parent.params.OrderNumber;
      this.form.versionNumber = this.$parent.params.versionNumber;
      this.form.revNumber = this.$parent.params.revNumber;

      this.form.noteTableData = JSON.stringify(this.noteTableData)
      this.form.newNoteArr = JSON.stringify(this.newNoteArr) 

      this.form.GeneralSpecINCIName = this.inclNameArr.join("_");
      this.form.GeneralSpecCommonName = this.commonNameArr.join("_");
      this.form.GeneralSpecBotanicalName = this.botanicalNameArr.join("_");
      this.form.GeneralSpecPartUsed = this.partUsedArr.join("_");
      
      this.form.baseCustomField = JSON.stringify(this.baseCustomFieldAttr) 
      this.form.genSpecCustomField = JSON.stringify(this.genSpecCustomFieldAttr) 
      this.form.analysisCustomField = JSON.stringify(this.analysisCustomFieldAttr) 
      this.form.fattyCustomField = JSON.stringify(this.fattyCustomFieldAttr)
      this.form.micTestsCustomField = JSON.stringify(this.micTestsCustomFieldAttr)
      this.form.tableInputCustomField = JSON.stringify(this.tableInputCustomFieldAttr) 

      let jsonStr = JSON.stringify(this.form);
      console.log(jsonStr);
      this.formData.productName = this.form.BaseInfoProductName;
      this.formData.itemCode = this.form.BaseInfoItemCode;
      this.formData.lot = this.form.BaseInfoLot;
      this.formData.jsonValue = jsonStr;
      this.formData.createTime = base.methods.formatTime(base.methods.getLocalTime(-7),"yyyy-MM-dd hh:mm:ss");
      console.log(this.formData);
      if (params.id) {
        this.form.revDate.push(this.$parent.revDateTime)
        jsonStr = JSON.stringify(this.form);
        this.formData.jsonValue = jsonStr;
        this.formData.id = params.id;
        this.$api.handleCnCoa
          .editCnCoaUrl(this.formData)
          .then(() => {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            // this.$router.push({
            //   path: "cn-coa",
            // });
            this.reloadPage(type);
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
      } else {
        this.formData.isWorkList = false
        this.$api.handleCnCoa
          .addCnCoaUrl(this.formData)
          .then((res) => {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            console.log("提交返回成功");
            console.log(res);
            this.$confirm("是否要返回列表页?", "Tips", {
              confirmButtonText: "Ok",
              cancelButtonText: "Cancel",
              distinguishCancelAndClose: true,
              type: "warning"
            }).then(() => {
              this.$router.push({
                path: "cn-coa"
              });
            });
            // this.$router.push({
            //   path: "cn-coa",
            // });
            this.reloadPage(type);
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
      }
    },
    reloadPage(type){
      if (type == "Save&New") {
        //清空数据
        this.$router.push({
          path: "cn-coaDetail",
          query: { productType: "TY-P" },
        });
        this.$router.go(0);
      }
    },
    getDetailById(params) {
      this.loading = true;
      this.$api.handleCnCoa.getCnCoaInfo(params).then((data) => {
        console.log(data);
        this.formData = data;
        if(this.formData.verifiedStatus <= 1){
          this.typeName = 'COA'
        }
        else{
          this.typeName = 'Working List'
        }
        this.form = JSON.parse(data.jsonValue);
        if(this.form.baseInfoCustomField){
          this.$parent.BaseInfoCustomFieldAttr = JSON.parse(this.form.baseInfoCustomField)
          this.baseInfoCustomFieldList = JSON.parse(this.form.baseInfoCustomField)
        }
        console.log(this.form.BaseInfoManufacturingDate);
        if (this.form.BaseInfoManufacturingDate == "NaN-aN-aN") {
          this.form.BaseInfoManufacturingDate = "";
          this.form.BaseInfoExpirationDate = "";
        }
        
        if(this.form.newNoteArr == undefined){
          this.form.newNoteArr = []
          this.newNoteArr = []
        }
        else{
          this.newNoteArr = JSON.parse(this.form.newNoteArr);
        }

        if(this.form.noteTableData){
          this.noteTableData = JSON.parse(this.form.noteTableData);
        }
        if(this.form.revNumber == undefined){
          this.form.revNumber = 0
        }
        if(this.form.revDate == undefined){
          this.form.revDate = []
        }
        this.$parent.params = this.form;
        this.loading = false;

        this.strSplit(this.form.GeneralSpecINCIName, "inclNameArr");
        this.strSplit(this.form.GeneralSpecCommonName, "commonNameArr");
        this.strSplit(this.form.GeneralSpecBotanicalName, "botanicalNameArr");
        this.strSplit(this.form.GeneralSpecPartUsed, "partUsedArr");

        
        if (this.form.baseCustomField) {
          this.baseCustomFieldAttr=JSON.parse(this.form.baseCustomField)
        }
        if (this.form.genSpecCustomField) {
          this.genSpecCustomFieldAttr=JSON.parse(this.form.genSpecCustomField)
        }
        if (this.form.analysisCustomField) {
          this.analysisCustomFieldAttr=JSON.parse(this.form.analysisCustomField)
        }
        if (this.form.fattyCustomField) {
          this.fattyCustomFieldAttr=JSON.parse(this.form.fattyCustomField)
        }
        if (this.form.micTestsCustomField) {
          this.micTestsCustomFieldAttr=JSON.parse(this.form.micTestsCustomField)
        }
        if (this.form.tableInputCustomField) {
          this.tableInputCustomFieldAttr=JSON.parse(this.form.tableInputCustomField)
        }
      });
    },
    manufacturingDateChange() {
      // var utc = require('dayjs/plugin/utc')
      // var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
      // var customParseFormat = require('dayjs/plugin/customParseFormat')
      // dayjs.extend(customParseFormat)
      // dayjs.extend(utc)
      // dayjs.extend(timezone)
      // var guess = dayjs.tz.guess();
      // console.info('时区：',dayjs.tz.guess());

      // console.warn('this.shelflifeNumber:',this.shelflifeNumber);
      // this.form.BaseInfoExpirationDate = dayjs(this.form.BaseInfoManufacturingDate).add(this.shelflifeNumber,'month');
      // if(guess.indexOf('America')!=-1){
      //   this.form.BaseInfoExpirationDate = dayjs(this.form.BaseInfoExpirationDate).add(1,'day');
      // }
      // console.info('this.form.BaseInfoExpirationDate22:',this.form.BaseInfoExpirationDate);
      
      // this.form.BaseInfoExpirationDate = this.formatTime(this.form.BaseInfoExpirationDate,"yyyy-MM-dd");
      
      // console.log("BaseInfoManufacturingDate:"+this.form.BaseInfoManufacturingDate)
      // console.log("BaseInfoExpirationDate:"+this.form.BaseInfoExpirationDate)

      // this.form.BaseInfoManufacturingDate = this.addDate(10,new Date(this.form.BaseInfoManufacturingDate),"day");
      // let startDate = this.addDate(10,this.form.BaseInfoManufacturingDate,"day");
      let startDate = this.form.BaseInfoManufacturingDate;
      console.info('startDate---:',startDate);

      // this.form.BaseInfoExpirationDate = this.addDate(this.shelflifeNumber,new Date(this.form.BaseInfoManufacturingDate),"month");
      if(this.shelflifeNumber==0){
        this.shelflifeNumber=24;
      }
      this.form.BaseInfoExpirationDate = dayjs(startDate).add(this.shelflifeNumber,'month');
      
      console.info('this.shelflifeNumber----:',this.shelflifeNumber);
      console.info('this.form.BaseInfoExpirationDate55:',this.formatTime(this.form.BaseInfoExpirationDate,"yyyy-MM-dd"));
      // this.form.BaseInfoExpirationDate = this.addDate(10,new Date(this.form.BaseInfoExpirationDate),"day");

      this.form.BaseInfoManufacturingDate = this.formatTime(this.form.BaseInfoManufacturingDate,"yyyy-MM-dd");
      this.form.BaseInfoExpirationDate = this.formatTime(this.form.BaseInfoExpirationDate,"yyyy-MM-dd");
      // this.form.BaseInfoExpirationDate = dayjs(this.form.BaseInfoExpirationDate).add(1,"day");
      
      console.log(this.form.BaseInfoManufacturingDate)
      console.log('aaaa:',this.form.BaseInfoExpirationDate)

    },
    printPage() {
      this.form.BaseInfoItemCode = this.$parent.params.BaseInfoItemCode;
      this.form.BaseInfoProductName = this.$parent.params.BaseInfoProductName;
      this.form.versionNumber = this.$parent.params.versionNumber;
      this.form.BaseInfoLot = this.$parent.params.BaseInfoLot;
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      //console.log(this.formModel);
      var printFileName = "";
      if(this.form.BaseInfoProductName != undefined && this.form.BaseInfoProductName != ""){
        printFileName = this.form.BaseInfoProductName.replace(":","~").replace("：", "~");
      }
      if(this.form.BaseInfoLot != undefined && this.form.BaseInfoLot != ""){
        printFileName = printFileName + "_" + this.form.BaseInfoLot;
      }
      printFileName = "(coa)" + printFileName + "_Rev." + this.form.revNumber
      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);

      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    tagHandle(array, tagCotext, index) {
      //tag标签点击事件
      console.info(array, tagCotext, index);
      this.$prompt("修改值-->" + tagCotext, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          array.splice(index, 1, value);
        })
        .catch(() => {});
    }, // ingredients标签操作
    handleClose(tag, arr) {
      this[arr].splice(this[arr].indexOf(tag), 1);
    },
    showInput: function (inputVisible, ref) {
      console.log(inputVisible);
      this[inputVisible] = true;
      this.$nextTick(() => {
        this.$refs[ref].$refs.input.focus();
      });
    },
    handleInputConfirm(value, arr, visible) {
      let inputValue = this[value];
      if (inputValue) {
        console.info(this[arr]);
        this[arr].push(inputValue);
      }

      this[visible] = false;
      this[value] = "";
    },
    strSplit(context, type) {
      this[type] = []; //清理旧有数据防止重复
      if (!context) {
        return;
      }
      let splitArr = context.split("_");
      if (splitArr.length == 1) {
        splitArr = context.split(",");
      }
      splitArr.forEach((v) => {
        if (v.trim().length != 0) {
          this[type].push(v);
        }
      });
    },
    addBaseCustomRow(type, index) {
      if (type == 1) {
        this.baseCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.baseCustomFieldAttr, index);
      }
    },
    addGenSpecCustomRow(type, index) {
      if (type == 1) {
        this.genSpecCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.genSpecCustomFieldAttr, index);
      }
    },
    addAnalysisCustomRow(type, index) {
      if (type == 1) {
        this.analysisCustomFieldAttr.push({ ...this.customField });
      } else {
        this.$delete(this.analysisCustomFieldAttr, index);
      }
    },
    addFattyCustomRow(type,index){
      if(type==1){
        this.fattyCustomFieldAttr.push({ ...this.customField });
      }
      else{
        this.$delete(this.fattyCustomFieldAttr,index)
      }
    },
    addMicTestsCustomRow(type,index){
      if(type==1){
        this.micTestsCustomFieldAttr.push({ ...this.customField });
      }
      else{
        this.$delete(this.micTestsCustomFieldAttr,index)
      }
    },
    addTableInput(type,index){
      if(type==1){
        this.tableInputCustomFieldAttr.push({ ...this.tableInputField });
      }
      else{
        this.$delete(this.tableInputCustomFieldAttr,index)
      }
    },
    getBaseInfoDetail(newVal){
      var jsonValue=JSON.parse(newVal.jsonValue)
      console.log(jsonValue);
      
      if(jsonValue.newNoteArr == undefined){
        this.form.newNoteArr = []
        this.newNoteArr = []
      }
      else{
        this.newNoteArr = JSON.parse(jsonValue.newNoteArr);
      }

      this.shelflifeNumber = jsonValue.shelflifeNumber!= undefined ? parseInt(jsonValue.shelflifeNumber): 36
      console.log("this.shelflifeNumber==="+this.shelflifeNumber)
      this.form.versionNumber = jsonValue.versionNumber;
      this.form.revNumber = jsonValue.revNumber;
      if(jsonValue.countryOfOrigin){
        this.form.BaseInfoCountryofOrigin=jsonValue.countryOfOrigin;
      }   
      this.form.GeneralSpecCommonName = jsonValue.commonName;
      this.form.GeneralSpecINCIName = jsonValue.inciName;
      this.form.GeneralSpecBotanicalName = jsonValue.botanicalName;
      this.form.GeneralSpecPartUsed = jsonValue.partUsed;
      this.form.GeneralSpecAppearance = jsonValue.appearance;
      this.form.otherIngredients = jsonValue.otherIngredients;
      this.strSplit(this.form.GeneralSpecINCIName, "inclNameArr");
      this.strSplit(this.form.GeneralSpecCommonName, "commonNameArr");
      this.strSplit(this.form.GeneralSpecBotanicalName, "botanicalNameArr");
      this.strSplit(this.form.GeneralSpecPartUsed, "partUsedArr");
      this.form.GeneralSpecCustomRowKey1= jsonValue.customKey1;
      this.form.GeneralSpecCustomRowValue1= jsonValue.customValue1;
      this.form.GeneralSpecCustomRowKey2= jsonValue.customKey2;
      this.form.GeneralSpecCustomRowValue2= jsonValue.customValue2;
      this.form.GeneralSpecCustomRowKey3= jsonValue.customKey3;
      this.form.GeneralSpecCustomRowValue3= jsonValue.customValue3;
      this.form.SpecificationColor = jsonValue.color;

      this.form.genSpecCustomField = jsonValue.genSpecCustomField;
      this.form.analysisCustomField = jsonValue.analysisCustomField;
      this.form.fattyCustomField = jsonValue.fattyCustomField;
      this.form.micTestsCustomField = jsonValue.micTestsCustomField;
      this.form.tableInputCustomField = jsonValue.tableInputCustomField;
      this.form.tableInput1 = jsonValue.tableInput1;
      this.form.tableInput2 = jsonValue.tableInput2;
      this.form.tableInput3 = jsonValue.tableInput3;
      this.form.tableInput4 = jsonValue.tableInput4;
      this.form.tableInput5 = jsonValue.tableInput5;
      if (this.form.genSpecCustomField) {
        this.genSpecCustomFieldAttr=JSON.parse(this.form.genSpecCustomField)
      }
      if (this.form.analysisCustomField) {
        this.analysisCustomFieldAttr=JSON.parse(this.form.analysisCustomField)
      }
      if (this.form.fattyCustomField) {
        this.fattyCustomFieldAttr=JSON.parse(this.form.fattyCustomField)
      }
      if (this.form.micTestsCustomField) {
        this.micTestsCustomFieldAttr=JSON.parse(this.form.micTestsCustomField)
      }
      if (this.form.tableInputCustomField) {
        this.tableInputCustomFieldAttr=JSON.parse(this.form.tableInputCustomField)
      }
      //#region  shTestArr
      this.form.SpecificationAcidValue = jsonValue.testLimitAcidValue;
      this.form.SpecificationPeroxideValue = jsonValue.testLimitPeroxideValue;
      this.form.SpecificationSaponificationValue = jsonValue.testLimitSaponificationValue;
      this.form.SpecificationUnsaponifiableMatter = jsonValue.testLimitUnsaponifiableMaster;
      this.form.SpecificationMoistureAndVolatiles = jsonValue.testLimitMoistureVolatiles;
      this.form.SpecificationTotalHeavyMetals = jsonValue.testLimitHeavyMetals;
      this.form.SpecificationArsenic = jsonValue.testLimitArsenic;
      this.form.SpecificationCadmium = jsonValue.testLimitCadmium;
      this.form.SpecificationLead = jsonValue.testLimitLead;
      this.form.SpecificationMercury = jsonValue.testLimitMercury;
      this.form.SpecificationPesticides = jsonValue.testLimitPesticides;
      
      this.form.TestMethodAcidValue = jsonValue.testMethodAcidValue;
      this.form.TestMethodPeroxideValue = jsonValue.testMethodPeroxideValue;
      this.form.TestMethodSaponificationValue = jsonValue.testMethodSaponificationValue;
      this.form.TestMethodUnsaponifiableMatter = jsonValue.testMethodUnsaponifiableMaster;
      this.form.TestMethodMoistureAndVolatiles = jsonValue.testMethodMoistureVolatiles;
      // this.form.TestMethodTotalHeavyMetals = jsonValue.testMethodHeavyMetals;
      this.form.TestMethodArsenic = jsonValue.testMethodArsenic;
      this.form.TestMethodCadmium = jsonValue.testMethodCadmium;
      this.form.TestMethodLead = jsonValue.testMethodLead;
      this.form.TestMethodMercury = jsonValue.testMethodMercury;
      this.form.TestMethodPesticides = jsonValue.testMethodPesticides;

      this.form.SpecificationCustomRowKey1 = jsonValue.testLimitCustomKey1;
      this.form.SpecificationCustomRowValue1 = jsonValue.testLimitCustomKey1Value;
      this.form.TestMethodCustomRowValue1 = jsonValue.testMethodCustomKey1Value;
      this.form.SpecificationCustomRowKey2 = jsonValue.testLimitCustomKey2;
      this.form.SpecificationCustomRowValue2 = jsonValue.testLimitCustomKey2Value;
      this.form.TestMethodCustomRowValue2 = jsonValue.testMethodCustomKey2Value;
      //this.analysisCustomFieldAttr = jsonValue.testCustomFieldAttr;

      //#endregion
      
      //#region fattyAcidComposition 
      this.form.SpecificationC80Caprylic= jsonValue.fattryLimitCaprylic;
      this.form.SpecificationC100Capric= jsonValue.fattryLimitCapric;
      
      this.form.SpecificationLauric = jsonValue.fattryLimitLauric;
      this.form.SpecificationMyristic = jsonValue.fattryLimitMyristic;
      this.form.SpecificationMyristoleic = jsonValue.fattryLimitMyristoleic;
      this.form.SpecificationArachidic = jsonValue.fattryLimitArachidic;
      this.form.SpecificationArachidonic = jsonValue.fattryLimitArachidonic;
      this.form.SpecificationEicosapentaenoic = jsonValue.fattryLimitEicosapentaenoic;
      this.form.SpecificationDocosahexaenoic = jsonValue.fattryLimitDocosahexaenoic;

      this.form.SpecificationC160Palmitic = jsonValue.fattryLimitPalmitica;
      this.form.SpecificationC161Palmitoleic = jsonValue.fattryLimitPalmitoleic;

      this.form.SpecificationC180Stearic = jsonValue.fattryLimitStearic;
      this.form.SpecificationC181Oleic = jsonValue.fattryLimitOleicVaccenic;
      this.form.SpecificationC182Linoleic = jsonValue.fattryLimitLinoleic;
      this.form.SpecificationC183αLinolenic = jsonValue.fattryLimitALinolenic;
      this.form.SpecificationOthers = jsonValue.fatterLimitOthers;
      
      this.form.SpecificationC183γLinoleicAcid= jsonValue.fattryLimitLinoleicacid;
      this.form.SpecificationC183PunicicAcid= jsonValue.fattryLimitPunicicacid;
      
      this.form.TestMethodC160Palmitic = jsonValue.fattryMethodPalmitica;
      this.form.TestMethodC161Palmitoleic = jsonValue.fattryMethodPalmitoleic;
      this.form.TestMethodC180Stearic = jsonValue.fattryMethodStearic;
      this.form.TestMethodC181Oleic = jsonValue.fattryMethodOleicVaccenic;
      this.form.TestMethodC182Linoleic = jsonValue.fattryMethodLinoleic;
      this.form.TestMethodC183αLinolenic = jsonValue.fattryMethodALinolenic;
      this.form.TestMethodOthers = jsonValue.fatterMethodOthers;
      this.form.TestMethodC80Caprylic= jsonValue.fattryMethodCaprylic;
      this.form.TestMethodC100Capric= jsonValue.fattryMethodCapric;
      this.form.TestMethodC183γLinoleicAcid= jsonValue.fattryMethodLinoleicacid;
      this.form.TestMethodC183PunicicAcid= jsonValue.fattryMethodPunicicacid;
      
      this.form.TestMethodLauric = jsonValue.fattryMethodLauric;
      this.form.TestMethodMyristic = jsonValue.fattryMethodMyristic;
      this.form.TestMethodMyristoleic = jsonValue.fattryMethodMyristoleic;
      this.form.TestMethodArachidic = jsonValue.fattryMethodArachidic;
      this.form.TestMethodArachidonic = jsonValue.fattryMethodArachidonic;
      this.form.TestMethodEicosapentaenoic = jsonValue.fattryMethodEicosapentaenoic;
      this.form.TestMethodDocosahexaenoic = jsonValue.fattryMethodDocosahexaenoic;

      
      this.form.MTSpecificationCustomRowKey1= jsonValue.fattryLimitKey1;
      this.form.MTSpecificationCustomRowValue1= jsonValue.fattryLimitKey1Value;
      this.form.MTTestMethodCustomRowValue1= jsonValue.fattryMethodKey1Value;
      this.form.MTSpecificationCustomRowKey2= jsonValue.fattryLimitKey2;
      this.form.MTSpecificationCustomRowValue2= jsonValue.fattryLimitKey2Value;
      this.form.MTTestMethodCustomRowValue2= jsonValue.fattryMethodKey2Value;
      this.form.MTSpecificationCustomRowKey3= jsonValue.fattryLimitKey3;
      this.form.MTSpecificationCustomRowValue3= jsonValue.fattryLimitKey3Value;
      this.form.MTTestMethodCustomRowValue3= jsonValue.fattryMethodKey3Value;
        
      //this.fattyCustomFieldAttr = jsonValue.fattyCustomFieldAttr;
      //#endregion

      //#region Microbiological Tests
      this.form.SpecificationTotalPlateCount = jsonValue.specificationTotalPlateCount;
      this.form.TestMethodTotalPlateCount = jsonValue.testMethodTotalPlateCount;

      this.form.SpecificationYeastAndMold = jsonValue.specificationYeastAndMold;
      this.form.TestMethodYeastAndMold = jsonValue.testMethodYeastAndMold;

      this.form.SpecificationEcoli = jsonValue.specificationEcoli;
      this.form.TestMethodEcoli = jsonValue.testMethodEcoli;

      this.form.SpecificationSalmonella = jsonValue.specificationSalmonella;
      this.form.TestMethodSalmonella = jsonValue.testMethodSalmonella;

      this.form.SpecificationStaphAureus = jsonValue.specificationStaphAureus;
      this.form.TestMethodStaphAureus = jsonValue.testMethodTaphAureus;

      this.form.SpecificationTotalColiforms = jsonValue.specificationTotalColiforms;
      this.form.TestMethodTotalColiforms = jsonValue.testMethodTotalColiforms;


      this.form.MicTestsCustomRowKey1 = jsonValue.microbiologicalCustomerKey1;
      this.form.MicTestsSpecificationCustomRowValue1 = jsonValue.microbiologicalCustomerValue1;
      this.form.MicTestsTestMethodCustomRowValue1 = jsonValue.microbiologicalTestMethodCustomerValue1;
      //#endregion
      
      console.log(this.form);
      console.log("=======OilShxto-baseInfoItemCodeDetail-end=========");
    },
    addNewNoteTableClick(){
      this.newNoteArr.push({
        content:this.newNote
      })
      this.newNote = ''
    },
    deleteNewNoteTableClick(index){
      this.newNoteArr.splice(index,1);
      // this.$delete(this.newNoteArr,index)
    },
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    this.formData.updateName = userInfo.username
  },
  mounted() {
    console.log("baseInfoItemCodeDetail")
    console.log(this.baseInfoItemCodeDetail)
    let params = { ...this.$route.query };
    if (params.id) {
      this.showVerified = true;
      this.getDetailById(params.id);
    }
    else{
      this.typeName = 'Working List'
    }
    let localData = localStorage.getItem("tpsDataKey");
    let data;
    if (localData != "undefined") {
      data = JSON.parse(localStorage.getItem("tpsDataKey"));
    }

    if (data) {
      Object.keys(data).forEach((key) => {
        this.form[key] = data[key];
      });
    }
  },
  watch: {
    baseInfoItemCodeDetail: {
      handler(newVal) {
        console.log("=======OilShxto-baseInfoItemCodeDetail-start=========");
        console.log(newVal);
        this.baseInfoCustomFieldList = newVal.BaseInfoCustomFieldAttr
        if(newVal.BaseInfoItemCodeDetail.jsonValue){
          this.getBaseInfoDetail(newVal.BaseInfoItemCodeDetail)
        }
        else{
          console.log('空数组')
        }
      },
      //为了发现内部的内容发生了变化把状态改变成true
      deep: true,
      immediate: true,//首次赋值的时候也会触发watch里面的handler事件
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
</style>
<style>
@import "../module/CoaStyle.css";
</style>

